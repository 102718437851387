import { isObject } from '../../../Helpers'
import constants from '../constants.json'
import { getSaleChannel, itemsParser } from '../utils'

const viewItemListParser = (payload, callbackSetPageInfo) => {
  if (!isObject(payload)) return {}

  const { data, flowType } = payload
  const { impressionData: { category = '', type = '' } = {} } = data
  const { FLOW_TYPES } = constants

  const isFlowTypeRecommender = flowType === FLOW_TYPES.RECOMMENDER
  const isFlowTypeSubstitution = flowType === FLOW_TYPES.SUBSTITUTION
  const isFlowTypeSubstitutionPlp = flowType === FLOW_TYPES.SUBSTITUTION_PLP

  const trackingId = data.tracking_id && isFlowTypeRecommender ? data.tracking_id : undefined

  const infoListPage = {
    tracking_id: trackingId,
    page: data.page,
    nbHits: data.nbHits,
    nbPages: data.nbPages,
    products: data.products,
  }

  const items = itemsParser(data.products, infoListPage)
  const saleChannel = getSaleChannel()

  const dataCollection = {
    items,
    flowType,
    tracking_id: trackingId,
    origin: data.origin,
    sale_channel: saleChannel,
  }

  if (isFlowTypeSubstitution) {
    dataCollection.tracking_id = data.tracking_id
    dataCollection.store_number = data.store_number
  }

  if (isFlowTypeSubstitutionPlp) {
    dataCollection.tracking_id = data.tracking_id
  }

  callbackSetPageInfo(infoListPage)
  return { products: data.products, category, type, dataCollection }
}

const viewItemListParserFromFlowTypeHome = (payload, callbackSetPageInfo) => {
  const { data } = payload
  const { dataCollection, ...params } = viewItemListParser(payload, callbackSetPageInfo)
  return {
    ...params,
    dataCollection: {
      ...dataCollection,
      section: data.section,
      section_name: data.sectionName,
      page: data.page,
    },
  }
}

const viewItemListParserFromFlowTypeSearch = (payload, callbackSetPageInfo) => {
  const { data } = payload
  const { dataCollection, ...params } = viewItemListParser(payload, callbackSetPageInfo)
  return {
    ...params,
    dataCollection: {
      ...dataCollection,
      section: data.section,
      section_name: data.sectionName,
      page: data.page,
      search_engine: data.dtSearchEngine ?? 'default',
    },
  }
}

export { viewItemListParser, viewItemListParserFromFlowTypeHome, viewItemListParserFromFlowTypeSearch }

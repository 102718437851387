import { createParser } from '@walmart/sophia-tagging'

export default createParser({
  fields: [
    { from: 'sectionName', to: 'section_name', validate: [] },
    { from: 'sectionItem', to: 'section_item', validate: [] },
    { from: 'sectionUrl', to: 'section_url', validate: [] },
    { from: 'saleChannel', to: 'sale_channel', validate: [] },
  ],
})

import { createParser } from '@walmart/sophia-tagging'
import field_params_mapper from '../params/field_params_mapper'

export default createParser({
  fields: [
    field_params_mapper,
    { from: 'selectedSODStoreId', to: 'sod_store_id', validate: [] },
    { from: 'selectedWalstoreStore.id', to: 'walmart_store_id', validate: [] },
    { from: 'saleChannel', to: 'sale_channel', validate: [] },
  ],
})

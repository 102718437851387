import { SET_SHOPPING_CART } from '../types'

export default (tenant) => (dispatch) => {
  dispatch({
    type: SET_SHOPPING_CART,
    payload: {
      tenant,
    },
  })
}

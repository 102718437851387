import { sendImpressionsToAnalytics } from '../../common/GoogleTagManager'
import constants from './constants.json'
import { changePriceFilterDataParser } from './parsers/changePriceFilterDataParser'
import {
  editCartDataParser,
  searchDataParser,
  viewItemDataParser,
  viewLoginDataParser,
  loginSuccessDataParser,
  loginErrorDataParser,
  clickDataParser,
  viewCartDataParser,
  viewItemListBuilder,
} from './builders'

const { EVENTS } = constants
const conditions = [
  {
    condition: (nameEvent) => nameEvent === EVENTS.SEARCH,
    buildData: (data) => searchDataParser(data),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.ITEM_LIST_VIEW,
    buildData: (data, callbackSetPageInfo) => viewItemListBuilder(data, callbackSetPageInfo),
    event: ({ category, dataCollection, products, type }) =>
      sendImpressionsToAnalytics(products, category, type, dataCollection),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.VIEW_ITEM,
    buildData: (data, _callbackSetPageInfo, pageInfo) => viewItemDataParser(data, pageInfo),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.ADD_TO_CART,
    buildData: (data, _callbackSetPageInfo, pageInfo) => editCartDataParser(data, pageInfo),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.REMOVE_FROM_CART,
    buildData: (data, _callbackSetPageInfo, pageInfo) => editCartDataParser(data, pageInfo),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.CHANGE_PRICE_FILTER,
    buildData: (data, _callbackSetPageInfo, pageInfo) => changePriceFilterDataParser(data, pageInfo),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.VIEW_LOGIN,
    buildData: (data) => viewLoginDataParser(data),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.LOGIN_SUCCESS,
    buildData: (data) => loginSuccessDataParser(data),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.LOGIN_ERROR,
    buildData: (data) => loginErrorDataParser(data),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.ANALYTICS_CLICK,
    buildData: (data) => clickDataParser(data),
  },
  {
    condition: (nameEvent) => nameEvent === EVENTS.VIEW_CART,
    buildData: (data, _callbackSetPageInfo, pageInfo) => viewCartDataParser(data, pageInfo),
  },
]
const sendDataToAnalytics = ({ nameEvent, data, callbackSetPageInfo, pageInfo }) => {
  const foundCondition = conditions.find(({ condition }) => condition(nameEvent))
  if (!foundCondition) return
  if (typeof foundCondition.event === 'function') {
    foundCondition.event(foundCondition.buildData(data, callbackSetPageInfo, pageInfo))
    return
  }
  foundCondition.buildData(data, callbackSetPageInfo, pageInfo)
}

export default sendDataToAnalytics

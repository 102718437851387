import slugify from './slugify'

export const appendSlugToProduct = (data) => {
  const product = {
    ...data,
    slug: slugify(`${data.brand}-${data.displayName}`),
  }

  return product
}

export const appendSlugToProducts = (data) => data.map((product) => appendSlugToProduct(product))
export const appendSlugToProductsCategory = (data) => ({
  ...data,
  products: appendSlugToProducts(data.products),
})

export const appendSearchEngineToProductResponse = (data, response) => ({
  ...data,
  dtSearchEngine: response?.headers?.['x-abtesting'] ?? 'default',
})

import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getTenant } from '../Helpers'

export const TenantContext = createContext({
  tenant: '',
  setTenant: () => {},
})

const Provider = ({ children }) => {
  const [tenant, setTenant] = useState('')

  useEffect(() => {
    setTenant(getTenant())
  }, [])

  useEffect(() => {
    document.body.dataset.tenant = tenant
  }, [tenant])

  return (
    <TenantContext.Provider
      value={{
        tenant,
        setTenant,
      }}
    >
      {tenant && children}
    </TenantContext.Provider>
  )
}

export const useTenant = () => useContext(TenantContext)

export const useIsTenant = (tenant) => useContext(TenantContext).tenant === tenant

Provider.propTypes = {
  children: PropTypes.element.isRequired,
}

export const TenantContextConsumer = TenantContext.Consumer

export default Provider

import { SET_CART } from '../types'

export default (cart) => (dispatch) => {
  dispatch({
    type: SET_CART,
    payload: {
      cart,
    },
  })
}

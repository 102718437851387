import React from 'react'
import PropTypes from 'prop-types'

const ImageWrapper = ({ url, children }) => {
  return (
    <picture>
      <source srcSet={url} type="image/webp" />
      {children}
    </picture>
  )
}

ImageWrapper.defaultProps = {
  url: '',
}

ImageWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.element,
}

export default ImageWrapper

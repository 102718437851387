import ClientAPI from '../common/ClientAPI'
import { logEventToDataCollection } from '../data-tagging'
import { EVENTS, FLOW_TYPES, ORIGINS } from '../data-tagging/constants'
import { getSaleChannel } from '../contexts/AnalyticsContext/utils'
import { getUserWalmartIdAndEmail } from './ProfileUtils'
import { getUserId } from '../contexts/UserDataContext'
const api = new ClientAPI()

const sendSPAClickEvent = async (product) => {
  api.sendAdvisorSpaEvent({
    type: 'click',
    eventUrl: product.tracking.clickUrl,
    endTime: Date.now(),
  })
}

const sendSPAViewEvent = async (product, startTime, metadata) => {
  api.sendAdvisorSpaEvent({
    type: 'view',
    eventUrl: product.tracking.viewUrl,
    endTime: Date.now(),
    startTime: startTime,
  })
  logEventToDataCollection({
    eventName: EVENTS.VIEW_PROMOTION,
    flowType: FLOW_TYPES.ADSERVE_PLA,
    origin: ORIGINS.PLP,
    data: {
      ...product,
      eventName: 'view',
      saleChannel: getSaleChannel(),
      walmartId: getUserWalmartIdAndEmail().walmartId,
      sessionId: getUserId(),
      searchTerm: metadata.query,
      position: metadata.position,
    },
  })
}

const sendSPAImpressionEvent = async (product, metadata) => {
  api.sendAdvisorSpaEvent({
    type: 'impression',
    eventUrl: product.tracking.impUrl,
  })
  logEventToDataCollection({
    eventName: EVENTS.IMPRESSIONS,
    flowType: FLOW_TYPES.ADSERVE_PLA,
    origin: ORIGINS.PLP,
    data: {
      ...product,
      eventName: 'impression',
      saleChannel: getSaleChannel(),
      walmartId: getUserWalmartIdAndEmail().walmartId,
      sessionId: getUserId(),
      searchTerm: metadata.query,
      position: metadata.position,
    },
  })
}

const sendTaggingAddToCart = async (product, metadata) => {
  logEventToDataCollection({
    eventName: EVENTS.ADD_TO_CART,
    flowType: FLOW_TYPES.ADSERVE_PLA,
    origin: metadata.origin,
    data: {
      ...product,
      eventName: 'add_to_cart',
      saleChannel: getSaleChannel(),
      walmartId: getUserWalmartIdAndEmail().walmartId,
      sessionId: getUserId(),
      searchTerm: metadata.query,
      position: metadata.position,
    },
  })
}

const sendTaggingClick = async (product, metadata) => {
  logEventToDataCollection({
    eventName: EVENTS.SELECT_PROMOTION,
    flowType: FLOW_TYPES.ADSERVE_PLA,
    origin: metadata.origin,
    data: {
      ...product,
      eventName: 'click',
      saleChannel: getSaleChannel(),
      walmartId: getUserWalmartIdAndEmail().walmartId,
      sessionId: getUserId(),
      searchTerm: metadata.query,
      position: metadata.position,
    },
  })
}

export { sendSPAClickEvent, sendSPAViewEvent, sendSPAImpressionEvent, sendTaggingAddToCart, sendTaggingClick }

import React, { useState, useEffect, createContext, useContext } from 'react'
import { useSelector, useStore, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  getCartQuantity,
  getCartItemsFromLocalStorage,
  getStoreFromLocalStorage,
  setStoreInLocalStorage,
  saveCartInStorage,
} from '../utils'

import { setStoreinCookieFromLocalStorage } from '../index.utils'
import constants from '../../../common/constants'
import { useTenant } from '../../../contexts/TenantContext'
import setCart from '../../../actions/setCart'
import setShoppingCartNameByTenant from '../../../actions/setShoppingCart'

export const ShoppingCartContext = createContext({
  cartQuantity: 0,
  shoppingCartItems: [],
  shoppingCartName: '',
  store: {},
  soldOutProducts: [],
  substituteProducts: [],
  recommendationTrackingId: '',
  saveStore: () => {},
  setStore: () => {},
  setSoldOutProducts: () => {},
  saveShoppingCart: () => {},
  setSubstituteProducts: () => {},
  setRecommendationTrackingId: () => {},
  isAddedAddressNumber: false,
  setIsAddedAddressNumber: () => {},
})

const Provider = ({ children }) => {
  const [cartQuantity, setCartQuantity] = useState(0)
  const dataStore = useStore()
  const [store, setStore] = useState({})
  const shoppingCartItems = useSelector((state) => state.shoppingCartReducer.shoppingCart)
  const shoppingCartName = useSelector((state) => state.shoppingCartReducer.shoppingCartName)
  const dispatch = useDispatch()
  const { tenant } = useTenant()
  const [soldOutProducts, setSoldOutProducts] = useState([])
  const [substituteProducts, setSubstituteProducts] = useState([])
  const [recommendationTrackingId, setRecommendationTrackingId] = useState('')
  const [isAddedAddressNumber, setIsAddedAddressNumber] = useState(false)

  const setShoppingCart = (shoppingCart) => dispatch(setCart(shoppingCart))

  const saveShoppingCart = (shoppingCart) => {
    setShoppingCart(shoppingCart)
    saveCartInStorage(shoppingCartName, shoppingCart)
  }

  const setShoppingCartName = () => {
    dispatch(setShoppingCartNameByTenant(tenant))
  }

  useEffect(() => {
    setCartQuantity(getCartQuantity(shoppingCartItems))
  }, [shoppingCartItems])

  useEffect(() => {
    setShoppingCart(getCartItemsFromLocalStorage(shoppingCartName))
  }, [shoppingCartName])

  const handleStorageEvent = (event) => {
    const {
      shoppingCartReducer: { shoppingCartName: cartName },
    } = dataStore.getState()

    if (event.key === cartName) {
      setShoppingCart(JSON.parse(event.newValue))
    } else if (event.key === 'store') {
      const newSODStore = JSON.parse(event.newValue)
      setStore(newSODStore)
    }
  }

  const saveStore = (newStore) => {
    setStore(newStore)
    setStoreInLocalStorage(newStore)
    setStoreinCookieFromLocalStorage()
  }

  useEffect(() => {
    setShoppingCartName()
    setStore(getStoreFromLocalStorage())

    window.addEventListener(constants.STORAGE_EVENT, handleStorageEvent)
    return () => {
      window.removeEventListener(constants.STORAGE_EVENT, handleStorageEvent)
    }
  }, [])

  return (
    <ShoppingCartContext.Provider
      value={{
        cartQuantity,
        shoppingCartItems,
        shoppingCartName,
        store,
        soldOutProducts,
        substituteProducts,
        recommendationTrackingId,
        isAddedAddressNumber,
        saveStore,
        setStore,
        setSoldOutProducts,
        saveShoppingCart,
        setSubstituteProducts,
        setRecommendationTrackingId,
        setIsAddedAddressNumber,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.element.isRequired,
}

export const useShoppingCartContext = () => useContext(ShoppingCartContext)

export default Provider

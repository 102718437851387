import events from './parsers'

export default {
  features: {
    base: true,
  },
  handlers: {
    onPublish: ({ eventName, flowType = '', origin = '', data }) => {
      const { _type = 'data', ...payload } = data
      const fullPayload =
        _type === 'ecommerce'
          ? { ecommerce: { flowType, origin, ...payload } }
          : { dataCollection: { flowType, origin, ...payload } }

      window.dataLayer.push({ event: eventName, ...fullPayload })
      // eslint-disable-next-line no-console
      console.debug(`[SETL] Publishing event: '${eventName}@${flowType}_${origin}'`)
      // eslint-disable-next-line no-console
      console.debug(`[SETL] Event payload is '${JSON.stringify(fullPayload)}'`)
    },
    onError: (message, payload) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug(`[SETL] Error in ${JSON.stringify(payload)}: ${message}`)
      }
    },
  },
  events: {
    ...events,
  },
}

import React, { createContext, useContext, useState, Fragment } from 'react'
import PropTypes from 'prop-types'

export const TagsContext = createContext({
  tagsConfig: {},
  setTagsConfig: () => {},
})

const renderTagsStyles = (tagsConfig) => {
  if (!tagsConfig || Object.keys(tagsConfig).length < 1) return undefined
  const { campaign = {} } = tagsConfig

  return (
    <Fragment>
      {Object.keys(campaign).map(
        (campaignName, index) =>
          // eslint-disable-next-line react/no-danger
          campaign[campaignName] && (
            <style key={index} dangerouslySetInnerHTML={{ __html: campaign[campaignName].style }} />
          )
      )}
    </Fragment>
  )
}

const Provider = ({ children }) => {
  const [tagsConfig, setTagsConfig] = useState({})

  return (
    <TagsContext.Provider value={{ tagsConfig, setTagsConfig }}>
      {renderTagsStyles(tagsConfig)}
      {children}
    </TagsContext.Provider>
  )
}

export const useTagsContext = () => useContext(TagsContext)

Provider.propTypes = {
  children: PropTypes.element.isRequired,
}

export const ConfigContextConsumer = TagsContext.Consumer

export default Provider

export const EVENTS = {
  SEARCH: 'search',
  SEARCH_RESULT: 'search_result',
  CATEGORY_RESULT: 'category_result',
  VIEW_ITEM_LIST: 'view_item_list',
  CHANGE_PRICE_FILTER: 'change_price_filter',
  VIEW_SLICK_SLIDER: 'view_slick_slider',
  SIGNUP_USER_FORM: 'view_signup_user_form',
  SIGNUP_PASSWORD_FORM: 'signup_password_form',
  SIGNUP_USER_SEND_EMAIL: 'signup_user_send_email',
  SELECT_PROMOTION: 'select_promotion',
  VIEW_PROMOTION: 'view_promotion',
  IMPRESSIONS: 'impressions',
  ADD_TO_CART: 'add_to_cart',
  SIGNUP_ERROR: 'signup_error',
  VIEW_PRODUCT_REVIEWS: 'view_product_reviews',
  CLICK_PAGE_SECTION_ITEM_LINK: 'click_page_section_item_link',
  VIEW_PAGE_SECTION: 'view_page_section',
  VIEW_BUYBOX_SELLER_OPTIONS: 'view_buybox_seller_options',
  LOGIN: {
    SUBMIT: 'login_submit',
    SUCCESS: 'login_success',
    ERROR: 'login_error',
    SIGNUP: 'signup_button',
    PASS_RECOVERY: 'password_recovery_button',
    VIEW_CONFIRM_ACCOUNT: 'view_confirm_account',
  },
  CONFIRM_ACCOUNT: {
    SUBMIT: 'confirm_account_submit',
    LEAVE: 'confirm_account_leave',
  },
  COMPLETE_ACCOUNT: {
    INVOKE: 'complete_account_invoke',
    SUBMIT: 'complete_account_submit',
    SUCCESS: 'complete_account_submit_success',
    LOAD_ERROR: 'complete_account_load_error',
    SUBMIT_ERROR: 'complete_account_submit_error',
  },
}

export const FLOW_TYPES = {
  DEFAULT: 'default',
  CHECKOUT: 'checkout',
  SEARCH: 'Search',
  CATEGORY: 'category_result',
  REGISTER: 'Register',
  ADSERVE_PLA: 'adserver_pla',
  PDP: 'pdp',
  CORPORATE_FOOTER: 'corporate_footer',
  FOOTER: 'footer',
  HEADER: 'header',
  BUYBOX: 'buybox',
  LOGIN: 'login',
  EMAIL_CONFIRMATION: 'email_confirmation',
}

export const ORIGINS = {
  PDP: 'pdp',
  PLP: 'plp',
  SIGNUP: 'Signup',
  HOME: 'home',
  FOOTER: 'footer',
  HEADER: 'header',
  LOGIN_VIEW: 'login_view',
  LOGIN_LATERAL_PANEL: 'login_lateral_panel',
  EMAIL_CONFIRMATION_VIEW: 'email_confirmation_view',
  COMPLETE_ACCOUNT_VIEW: 'complete_account_view',
}

export const SUB_ORIGINS = {
  BUYBOX_MODAL: 'buybox_modal',
  COMBINABLE: 'combinable',
}

export const EVENT_TYPES = {
  ECOMMERCE: 'ecommerce',
}

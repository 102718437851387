import React from 'react'
import PropTypes from 'prop-types'
import { getSavingText, getSaving } from '../../index.utils'
import './index.css'

const PackCompleted = ({ packSize, packPrice, regularUnitPrice, quantity }) => {
  const saving = getSaving(packPrice, packSize, regularUnitPrice) * (quantity / packSize)
  return <div className="complete-product-pack__pack-completed">{getSavingText(saving)}</div>
}

PackCompleted.propTypes = {
  packSize: PropTypes.number.isRequired,
  packPrice: PropTypes.number.isRequired,
  regularUnitPrice: PropTypes.number.isRequired,
}

export default PackCompleted

import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getRawItem, setRawItem } from '../Helpers/localStorageHelper'
import { v4 as uuidv4 } from 'uuid'

const dataStore = () => {
  const key = 'buysmartUserData'
  const get = () => {
    const data = getRawItem(key)
    try {
      return JSON.parse(data || '{}')
    } catch (error) {
      return {}
    }
  }
  const set = (name, value) => {
    const data = {
      ...get(),
      [name]: value,
      created: get().created || Date.now(),
      updated: Date.now(),
    }
    setRawItem(key, JSON.stringify(data))
  }
  const isId = () => (get() ? !!get().id : false)
  return {
    set,
    get,
    isId,
  }
}

export const getUserId = () => {
  try {
    const id = typeof localStorage !== 'undefined' ? dataStore().get().id : ''
    return id
  } catch (e) {
    return uuidv4()
  }
}

const UserDataContext = createContext({
  data: null,
  setData: () => {},
})

const Provider = ({ children }) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    if (data === null) {
      if (!dataStore().isId()) {
        const id = uuidv4()
        dataStore().set('id', id)
      }
      setData(dataStore().get())
    }
  }, [data, setData])
  return (
    <UserDataContext.Provider
      value={{
        data,
        setData: dataStore().set,
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}

export const useUserData = () => useContext(UserDataContext)

Provider.propTypes = {
  children: PropTypes.element.isRequired,
}

export const UserDataContextConsumer = UserDataContext.Consumer

export default Provider

import { removeItem, getJSONItem, saveItemAsJSON } from '../Helpers/localStorageHelper'
import constants from './constants'

const saveProfile = (profile) => saveItemAsJSON(constants.profile.PROFILE, profile)

const clearProfile = () => removeItem(constants.profile.PROFILE)
const clearDispatchType = () => removeItem(constants.delivery.STORAGE_KEY_DISPATCH)
const clearSelectedStore = () => removeItem(constants.stores.STORAGE_KEY_STORE)

const getUserFirstName = () => {
  const profile = getJSONItem(constants.profile.PROFILE)
  return profile ? profile[constants.profile.FIRST_NAME] : undefined
}

const getUserWalmartIdAndEmail = () => {
  const profile = getJSONItem(constants.profile.PROFILE)
  const walmartId = profile ? profile[constants.profile.WALMART_ID] : undefined
  const email = profile ? profile[constants.profile.EMAIL] : undefined

  return {
    walmartId,
    email,
  }
}

export { saveProfile, clearProfile, clearDispatchType, clearSelectedStore, getUserFirstName, getUserWalmartIdAndEmail }

import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import sendDataToAnalytics from './wrapper'
import constants from './constants.json'
import { handleFlowType } from './utils'

export const analyticsContext = createContext({
  getDataForGoogleAnalytics: () => {},
})

const Provider = ({ children }) => {
  const [listPageInfo, setListPageInfo] = useState({
    page: 0,
    nbHits: 0,
    nbPages: 0,
    products: [],
    tracking_id: undefined,
  })

  const getDataForGoogleAnalytics = ({ eventName, flowType, data }) => {
    const isAnAllowedEvent = Object.values(constants.EVENTS).find((event) => event === eventName)
    let computedFlowType = flowType === '' ? 'none' : flowType

    if (!isAnAllowedEvent) return

    computedFlowType = handleFlowType(eventName, computedFlowType, data)

    const payload = {
      flowType: computedFlowType,
      data,
    }

    sendDataToAnalytics({
      nameEvent: eventName,
      data: payload,
      callbackSetPageInfo: setListPageInfo,
      pageInfo: listPageInfo,
    })
  }

  return <analyticsContext.Provider value={{ getDataForGoogleAnalytics }}>{children}</analyticsContext.Provider>
}

export const useAnalytics = () => useContext(analyticsContext)

Provider.propTypes = {
  children: PropTypes.element.isRequired,
}

export const analyticsContextConsumer = analyticsContext.Consumer

export default Provider

const validateLocalStorage = () => {
  return typeof localStorage !== 'undefined' && localStorage !== null
}

const getRawItem = (itemKey, defaultValue = undefined) => {
  let result
  try {
    if (validateLocalStorage()) {
      result = localStorage.getItem(itemKey)
    }
  } catch (error) {
    console.error(error)
  }

  return result || defaultValue
}

const setRawItem = (itemKey, value) => {
  try {
    if (validateLocalStorage()) {
      localStorage.setItem(itemKey, value)
    }
  } catch (error) {
    console.error(error)
  }
}

const removeItem = (itemKey) => {
  try {
    if (validateLocalStorage()) {
      localStorage.removeItem(itemKey)
    }
  } catch (error) {
    console.error(error)
  }
}

const getJSONItem = (itemKey, defaultValue = undefined) => {
  const result = getRawItem(itemKey)
  return result ? JSON.parse(result) : defaultValue
}

const saveItemAsJSON = (itemKey, value) => setRawItem(itemKey, JSON.stringify(value))

export { getRawItem, setRawItem, removeItem, getJSONItem, saveItemAsJSON }

/* eslint-disable camelcase */
import constants from './constants.json'
import { isObject, getPrice, itemsParser, getSaleChannel, getDataCollection } from './utils'
import { sendCartDataToAnalytics, sendDetailProductDataToAnalytics } from '../../common/GoogleTagManager'
import {
  viewItemListParser,
  viewItemListParserFromFlowTypeHome,
  viewItemListParserFromFlowTypeSearch,
} from './parsers/viewItemListParser'

const {
  EVENTS,
  COMMON,
  FLOW_TYPES,
  DEFAULT_CORRELATION_ID,
  INFO_LIST_PAGE,
  DEFAULT_QUANTITY,
  ORIGINS_TAGGING,
  ORIGINS,
} = constants

const searchDataParser = (payload) => {
  if (!isObject(payload)) return {}

  const { data } = payload

  const saleChannel = getSaleChannel()

  const dataCollection = {
    correlation_id: DEFAULT_CORRELATION_ID,
    page: data.page,
    origin: data.origin,
    search_term: data.query,
    sale_channel: saleChannel,
    flowType: payload.flowType,
    store_number: data.selectedWalstoreStore?.id || data.selectedSODStoreId,
    search_engine: data.dtSearchEngine,
  }

  return window.dataLayer.push({
    event: 'search',
    dataCollection,
  })
}

const viewItemListBuilder = (payload, callbackSetPageInfo) => {
  if (!isObject(payload)) return {}
  const { flowType } = payload

  const parsers = {
    [FLOW_TYPES.HOME]: (...params) => viewItemListParserFromFlowTypeHome(...params),
    [FLOW_TYPES.SEARCH]: (...params) => viewItemListParserFromFlowTypeSearch(...params),
    DEFAULT_PARSER: (...params) => viewItemListParser(...params),
  }

  if (parsers[flowType]) {
    return parsers[flowType](payload, callbackSetPageInfo)
  }
  return parsers.DEFAULT_PARSER(payload, callbackSetPageInfo)
}

const viewItemDataParser = (payload, infoListPage) => {
  if (!isObject(payload)) return {}

  const { data, flowType } = payload
  const items = itemsParser([data], infoListPage)
  const finalPrice = getPrice(data.price)
  const saleChannel = getSaleChannel()

  const isFlowTypeRecommender = flowType === FLOW_TYPES.RECOMMENDER
  const tracking_id = infoListPage.tracking_id && isFlowTypeRecommender ? infoListPage.tracking_id : undefined

  const dataCollection = {
    items,
    flowType,
    tracking_id,
    value: finalPrice,
    origin: data.origin,
    currency: COMMON.CURRENCY,
    sale_channel: saleChannel,
    store_number: data.selectedWalstoreStore?.id || data.selectedSODStoreId,
    search_engine: data?.dtSearchEngine,
  }

  return sendDetailProductDataToAnalytics(data, data.origin, dataCollection)
}

const editCartDataParser = (payload, infoListPage) => {
  if (!isObject(payload)) return {}

  const { data, flowType } = payload

  const infoList =
    flowType === FLOW_TYPES.SHOPPING_CART
      ? {
          page: INFO_LIST_PAGE.DEFAULT_PAGE,
          nbHits: data?.shoppingCartItems?.length,
          nbPages: INFO_LIST_PAGE.DEFAULT_NGPAGES,
          products: data?.shoppingCartItems,
        }
      : infoListPage

  const isFlowTypeRecommender = flowType === FLOW_TYPES.RECOMMENDER
  const isFlowTypeSubstitution = flowType === FLOW_TYPES.SUBSTITUTION
  const isFlowTypeSubstitutionPlp = flowType === FLOW_TYPES.SUBSTITUTION_PLP
  const isFlowTypeSearch = flowType === FLOW_TYPES.SEARCH
  const isFlowTypeCategory = flowType === FLOW_TYPES.CATEGORY_RESULT

  let trackingId = infoListPage.tracking_id && isFlowTypeRecommender ? infoListPage.tracking_id : undefined
  const origin = data.origin === ORIGINS_TAGGING.SEARCH ? ORIGINS.PLP : data.origin

  if (isFlowTypeSubstitutionPlp) {
    trackingId = data.tracking_id
    if (!data.listIndex) {
      data.listIndex = infoList.products.find((p) => p.ID === data.ID)?.listIndex
    }
  }

  const items = itemsParser([data], infoList)
  const finalPrice = getPrice(data.price)
  const saleChannel = getSaleChannel()

  const dataCollection = {
    items,
    flowType,
    tracking_id: trackingId,
    value: finalPrice,
    origin,
    sub_origin: data.subOrigin,
    currency: COMMON.CURRENCY,
    sale_channel: saleChannel,
    session_id: data.sessionId,
    search_term: data.searchTerm,
    banner_slot_info: data.bannerSlotInfo,
    is_sponsored: data.isSponsored,
    position: data.position,
  }

  if (isFlowTypeSubstitution) {
    dataCollection.tracking_id = data.tracking_id
  }

  if (isFlowTypeSearch || isFlowTypeCategory) {
    dataCollection.search_engine = data.dtSearchEngine ?? 'default'
  }

  if (isFlowTypeCategory) {
    dataCollection.search_categories = data.dtSearchCategories ?? ''
  }

  return sendCartDataToAnalytics(data, data.eventEcommerce, DEFAULT_QUANTITY, data.origin, dataCollection)
}

const viewLoginDataParser = (payload) => {
  if (!isObject(payload)) return {}

  const { data } = payload
  const dataCollection = getDataCollection(data)

  return window.dataLayer.push({
    event: EVENTS.VIEW_LOGIN,
    dataCollection,
  })
}

const loginSuccessDataParser = (payload) => {
  if (!isObject(payload)) return {}

  const { data } = payload
  const dataCollection = getDataCollection(data)

  return window.dataLayer.push({
    event: EVENTS.LOGIN_SUCCESS,
    dataCollection,
  })
}

const loginErrorDataParser = (payload) => {
  if (!isObject(payload)) return {}

  const { data } = payload
  const dataCollectionBase = getDataCollection(data)

  const dataCollection = {
    ...dataCollectionBase,
    error_status: data.errorStatus,
    error_message: data.errorMessage,
  }

  return window.dataLayer.push({
    event: EVENTS.LOGIN_ERROR,
    dataCollection,
  })
}

const clickDataParser = (payload) => {
  if (!isObject(payload)) return {}

  const { data } = payload
  const dataCollectionBase = getDataCollection(data)

  const dataCollection = {
    ...dataCollectionBase,
    clickId: data.clickId,
  }

  return window.dataLayer.push({
    event: EVENTS.ANALYTICS_CLICK,
    dataCollection,
  })
}

const viewCartDataParser = (payload) => {
  const { data, flowType } = payload
  const { origin } = data
  const saleChannel = getSaleChannel()

  const infoList = {
    page: INFO_LIST_PAGE.DEFAULT_PAGE,
    nbHits: data?.shoppingCartItems?.length,
    nbPages: INFO_LIST_PAGE.DEFAULT_NGPAGES,
    products: data?.shoppingCartItems,
  }

  const items = itemsParser(data?.shoppingCartItems, infoList)

  const dataCollection = {
    items,
    flowType,
    origin,
    sale_channel: saleChannel,
  }

  window.dataLayer.push({
    event: 'view_cart',
    dataCollection: dataCollection,
  })
}

export {
  searchDataParser,
  viewItemDataParser,
  editCartDataParser,
  viewItemListParser,
  viewLoginDataParser,
  loginSuccessDataParser,
  clickDataParser,
  viewItemListBuilder,
  viewCartDataParser,
  loginErrorDataParser,
}

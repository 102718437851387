export const CONSTANTS = Object.freeze({
  deliveryAddressForm: 'deliveryAddressForm',
  shoppingCart: 'shoppingCart',
  login: 'login',
  storeSelector: 'storeSelector',
  none: 'none',
  soldOutItemsValidation: 'soldOutItemsValidation',
  soldOutProducts: 'soldOutProducts',
  promotionsValidation: 'PromotionsValidation',
  pickupMethod: 'pickupMethod',
  homeDeliveryMethod: 'homeDeliveryMethod',
})

export const ACTIONS = Object.freeze({
  OPEN_STORE_SELECTOR: 'OPEN_STORE_SELECTOR',
  SHOW_STORE_SELECTOR_FROM_CART: 'SHOW_STORE_SELECTOR_FROM_CART',
  SHOW_DELIVERY_ADDRESS_FORM: 'SHOW_DELIVERY_ADDRESS_FORM',
  SHOW_LOGIN: 'SHOW_LOGIN',
  SHOW_CART: 'SHOW_CART',
  SAVE_STORE: 'SAVE_STORE',
  SHOW_SOLD_OUT_VALIDATION: 'SHOW_SOLD_OUT_VALIDATION',
  SHOW_SOLD_OUT_PRODUCTS: 'SHOW_SOLD_OUT_PRODUCTS',
  CLOSE_PANEL: 'CLOSE_PANEL',
  OPEN_CART: 'OPEN_CART',
  OPEN_CART_MODAL: 'OPEN_CART_MODAL',
  CLOSE_CART_MODAL: 'CLOSE_CART_MODAL',
  OPEN_CART_MODAL_ERROR: 'OPEN_CART_MODAL_ERROR',
  CLOSE_CART_MODAL_ERROR: 'CLOSE_CART_MODAL_ERROR',
  OPEN_CART_MODAL_COORDINATES_ERROR: 'OPEN_CART_MODAL_COORDINATES_ERROR',
  CLOSE_CART_MODAL_COORDINATES_ERROR: 'CLOSE_CART_MODAL_COORDINATES_ERROR',
  SHOW_LOADING_OVERLAY: 'SHOW_LOADING_OVERLAY',
  OVERLAY_ERROR: 'OVERLAY_ERROR',
  MODAL_ERROR: 'MODAL_ERROR',
  CLOSE_ERROR_MODAL: 'CLOSE_ERROR_MODAL',
  OPEN_SUBSTITUTION_VIEW: 'OPEN_SUBSTITUTION_VIEW',
  CLOSE_SUBSTITUTION_VIEW: 'CLOSE_SUBSTITUTION_VIEW',
  OPEN_ALTERNATIVE_PRODUCTS_MODAL: 'OPEN_ALTERNATIVES_PRODUCTS_MODAL',
  CLOSE_ALTERNATIVE_PRODUCTS_MODAL: 'CLOSE_ALTERNATIVES_PRODUCTS_MODAL',
  SHOW_PROMOTIONS_VALIDATION: 'SHOW_PROMOTIONS_VALIDATION',
  SET_STORE_SELECTOR_TAB_PICKUP: 'SET_STORE_SELECTOR_TAB_PICKUP',
  SET_STORE_SELECTOR_TAB_HOMEDELIVERY: 'SET_STORE_SELECTOR_TAB_HOMEDELIVERY',
  SET_STORE_SELECTOR_TAB_DEFAULT: 'SET_STORE_SELECTOR_TAB_DEFAULT',
})

export const SUB_STEP = Object.freeze({
  FROM_CART: 'FROM_CART',
  FROM_ADD_TO_CART: 'FROM_ADD_TO_CART',
})

export default {
  CONSTANTS,
  ACTIONS,
}

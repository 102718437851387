import { EVENTS, FLOW_TYPES } from '../constants'
import category_result_parser from './category_result_parser'
import search_result_parser from './search_result_parser'
import search_request_parser from './search_request_parser'
import change_price_filter_parser from './change_price_filter_parser'
import view_slick_slider from './view_slick_slider'
import view_signup_form from './login/view_signup_form_parser'
import advisor_spa_parser from './advisor_spa_parser'
import signup_error_parser from './login/signup_error_parser'
import view_product_reviews_parser from './view_product_reviews_parser'
import view_page_section from './view_page_section'
import click_page_section_item_link_parser from './click_page_section_item_link_parser'
import view_buybox_seller_options_parser from './view_buybox_seller_options'
import login_parser from './login/login_parser'
import login_error_parser from './login/login_error_parser'
import complete_account_parser from './account/complete_account_parser'

const config = {
  [EVENTS.SEARCH_RESULT]: {
    parser: search_result_parser,
  },
  [EVENTS.CATEGORY_RESULT]: {
    parser: category_result_parser,
  },
  [EVENTS.SEARCH]: {
    parser: search_request_parser,
  },
  [EVENTS.CHANGE_PRICE_FILTER]: {
    parser: change_price_filter_parser,
  },
  [EVENTS.VIEW_SLICK_SLIDER]: {
    parser: view_slick_slider,
  },
  [EVENTS.SIGNUP_USER_FORM]: {
    parser: view_signup_form,
  },
  [EVENTS.SIGNUP_PASSWORD_FORM]: {
    parser: view_signup_form,
  },
  [EVENTS.SIGNUP_USER_SEND_EMAIL]: {
    parser: view_signup_form,
  },
  [EVENTS.SIGNUP_ERROR]: {
    parser: signup_error_parser,
  },
  [EVENTS.SELECT_PROMOTION]: {
    parser: {
      [FLOW_TYPES.ADSERVE_PLA]: advisor_spa_parser,
    },
  },
  [EVENTS.VIEW_PROMOTION]: {
    parser: {
      [FLOW_TYPES.ADSERVE_PLA]: advisor_spa_parser,
    },
  },
  [EVENTS.IMPRESSIONS]: {
    parser: {
      [FLOW_TYPES.ADSERVE_PLA]: advisor_spa_parser,
    },
  },
  [EVENTS.ADD_TO_CART]: {
    parser: {
      [FLOW_TYPES.ADSERVE_PLA]: advisor_spa_parser,
    },
  },
  [EVENTS.VIEW_PRODUCT_REVIEWS]: {
    parser: view_product_reviews_parser,
  },
  [EVENTS.VIEW_PAGE_SECTION]: {
    parser: view_page_section,
  },
  [EVENTS.CLICK_PAGE_SECTION_ITEM_LINK]: {
    parser: click_page_section_item_link_parser,
  },
  [EVENTS.VIEW_BUYBOX_SELLER_OPTIONS]: {
    parser: view_buybox_seller_options_parser,
  },
  [EVENTS.LOGIN.SUBMIT]: {
    parser: login_parser,
  },
  [EVENTS.LOGIN.SUCCESS]: {
    parser: login_parser,
  },
  [EVENTS.LOGIN.ERROR]: {
    parser: login_error_parser,
  },
  [EVENTS.LOGIN.PASS_RECOVERY]: {
    parser: login_parser,
  },
  [EVENTS.LOGIN.SIGNUP]: {
    parser: login_parser,
  },
  [EVENTS.LOGIN.VIEW_CONFIRM_ACCOUNT]: {
    parser: login_parser,
  },
  [EVENTS.CONFIRM_ACCOUNT.SUBMIT]: {
    parser: login_parser,
  },
  [EVENTS.CONFIRM_ACCOUNT.LEAVE]: {
    parser: login_parser,
  },
  [EVENTS.COMPLETE_ACCOUNT.INVOKE]: {
    parser: complete_account_parser,
  },
  [EVENTS.COMPLETE_ACCOUNT.SUBMIT]: {
    parser: complete_account_parser,
  },
  [EVENTS.COMPLETE_ACCOUNT.SUCCESS]: {
    parser: complete_account_parser,
  },
  [EVENTS.COMPLETE_ACCOUNT.LOAD_ERROR]: {
    parser: complete_account_parser,
  },
  [EVENTS.COMPLETE_ACCOUNT.SUBMIT_ERROR]: {
    parser: complete_account_parser,
  },
}

export default config

import constants from '../common/constants'

const isAJpegImage = (contentType) => contentType === 'image/jpeg'

const isAWebpImage = (contentType) => contentType === 'image/webp'

export const confirmImageExists = async (imageUrl) => {
  try {
    const { headers, ok } = await fetch(imageUrl)
    return ok && (isAJpegImage(headers.get('content-type')) || isAWebpImage(headers.get('content-type')))
  } catch (error) {
    return false
  }
}

export const getImageUrl = async (imageUrl = '') => {
  const imageExists = imageUrl.length > 0 && (await confirmImageExists(imageUrl))
  return imageExists ? imageUrl : constants.PRODUCT_WITHOUT_IMAGE
}

export const getAvailableImages = async (imageUrls = []) => {
  const imageResponses = imageUrls && (await Promise.all(imageUrls.map((imageUrl) => confirmImageExists(imageUrl))))
  return imageUrls.filter((_, index) => imageResponses[index])
}

export const getUrlObject = (url) => {
  try {
    return new URL(url)
  } catch (e) {
    return {}
  }
}

export const hasHost = (url, hostname) => {
  const urlObject = getUrlObject(url)
  return urlObject?.hostname === hostname
}

export const setURLParams = (url, params = {}) => {
  const urlObject = getUrlObject(url)
  if (!urlObject.href) {
    return url
  }
  Object.keys(params).forEach((paramKey) => urlObject.searchParams.set(paramKey, params[paramKey]))
  return urlObject.href
}

export const getImageSizeAndFormatParams = (imageUrl, size = 180) => {
  if (hasHost(imageUrl, constants.WALMART_IMAGE_HOST)) {
    return { odnWidth: size, odnHeight: size }
  }
  if (hasHost(imageUrl, constants.LIQUID_PIXEL_REDIRECT_HOST)) {
    return {
      scale: `size[${size}x${size}]`,
      sink: 'format[webp]',
    }
  }
  return {}
}

export const replaceFormatParamFromLiquid = (url, format) => url.replace(/format\[(.*?)\]/g, `format[${format}]`)

export const addPropertiesToStringImgTag = (text = '', properties = '') => {
  if (typeof text != 'string') {
    return text
  }
  return text.replace(/<img (.*?)>/g, (_, imgProperties) => {
    return `<img ${properties} ${imgProperties}>`
  })
}

export const formatLiquidImagesFromText = (text, imgProperties) => {
  const imgWithProperties = addPropertiesToStringImgTag(text, imgProperties)
  return replaceFormatParamFromLiquid(imgWithProperties, 'webp')
}

import React from 'react'
import PropTypes from 'prop-types'
import texts from '../../../common/texts.json'
import { priceFormatter } from '../../../Helpers'

const Price = ({ className, price, quantity }) => {
  const priceFormatted = priceFormatter(price)
  const unitsAndPriceSeparator = texts.PRODUCT_PRICES.UNITS_AND_PRICE_SEPARATOR
  const priceLabel = () =>
    (quantity > 0 ? `${quantity} ${unitsAndPriceSeparator} ${priceFormatted}` : `${priceFormatted}`)

  return <span className={className}>{priceLabel()}</span>
}

Price.propTypes = {
  className: PropTypes.string,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number,
}

Price.defaultProps = {
  className: undefined,
  quantity: undefined,
}

export default Price

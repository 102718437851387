export default {
  from: 'state',
  to: 'search_params',
  validate: [],
  transform: (value) => {
    return Object.keys(value).reduce((acc, key) => {
      // format keys to snake_case
      const formattedKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
      return { ...acc, [formattedKey]: value[key] }
    }, {})
  },
}

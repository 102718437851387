import { getJSONItem, saveItemAsJSON } from '../../Helpers/localStorageHelper'
import constants from '../../common/constants'
import getSkusAvailabilityAndPrice from '../../common/clients/getSkusAvailabilityAndPrice'

const updateShoppingCartItems = (skus, shoppingCartItems) =>
  shoppingCartItems.map((item) => ({
    ...item,
    ...skus[item.sku],
  }))

const updateCartItems = async (shoppingCartItems, storeId) => {
  let updatedShoppingCartItems

  if (shoppingCartItems && shoppingCartItems.length > 0) {
    const skus = await getSkusAvailabilityAndPrice(shoppingCartItems, storeId)
    if (skus) {
      updatedShoppingCartItems = updateShoppingCartItems(skus, shoppingCartItems)
    }
  }

  return updatedShoppingCartItems
}

const getCartItemsFromLocalStorage = (shoppingCartName) => {
  const shoppingCartLS = getJSONItem(shoppingCartName)
  return shoppingCartLS === undefined ? [] : shoppingCartLS
}

const MAX_QUANTITY_DISPLAY_ITEMS = 100

const getCartQuantity = (cartItems) => {
  if (cartItems && cartItems.length > 0) {
    const cartQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0)
    return cartQuantity < MAX_QUANTITY_DISPLAY_ITEMS ? cartQuantity : '+99'
  }
  return 0
}

const getSoldOutProducts = (cartItems) =>
  cartItems.filter((item) => !item.available || item.quantity > item.maxAvailable)

const getStoreFromLocalStorage = () => getJSONItem(constants.stores.STORAGE_KEY_STORE)

const setStoreInLocalStorage = (store) => saveItemAsJSON(constants.stores.STORAGE_KEY_STORE, store)

const saveCartInStorage = (shoppingCartName, shoppingCartItems) => saveItemAsJSON(shoppingCartName, shoppingCartItems)

const addIndexPropertyFromOne = (array) => array.map((item, index) => ({ ...item, listIndex: index + 1 }))

export {
  getCartItemsFromLocalStorage,
  getCartQuantity,
  getStoreFromLocalStorage,
  setStoreInLocalStorage,
  updateCartItems,
  getSoldOutProducts,
  saveCartInStorage,
  addIndexPropertyFromOne,
}

import { getRawItem } from '../../Helpers/localStorageHelper'
import { isObjectEmpty } from '../../Helpers'

export const injectCookiesFromLocalStorage = () => {
  try {
    setCookie('buysmartUserData', getRawItem('buysmartUserData'))
    setCookie('XFlowId', getRawItem('XFlowId'))
    return true
  } catch (_e) {
    return false
  }
}

const getDomainName = () => {
  const { hostname } = window.location
  return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1)
}

export const setCookie = (name, value) => {
  window.document.cookie = `${name}=${value};domain=${getDomainName()};path=/`
}

export const setStoreinCookieFromLocalStorage = () => {
  try {
    const objectStore = getRawItem('store')
    !isObjectEmpty(objectStore) ? setCookie('storeId', objectStore) : setCookie('storeId', '{"id":""}')
    return true
  } catch (_e) {
    return false
  }
}

import { createParser } from '@walmart/sophia-tagging'

export default createParser({
  fields: [
    { from: 'sku', to: 'item_id', validate: [] },
    { from: 'itemNumber', to: 'item_number', validate: [] },
    { from: 'displayName', to: 'name', validate: [] },
    { from: 'description', to: 'description', validate: [] },
    { from: 'brand', to: 'brand', validate: [] },
    { from: 'attributes.department', to: 'department', validate: [] },
    { from: 'position', to: 'position', validate: [] },
    { from: 'saleChannel', to: 'sale_channel', validate: [] },
    { from: 'eventName', to: 'event_name', validate: [] },
    { from: 'searchTerm', to: 'search_term', validate: [] },
    { from: 'correlationId', to: 'correlation_id', validate: [] },
    { from: 'walmartId', to: 'walmart_id', validate: [] },
    { from: 'sessionId', to: 'session_id', validate: [] },
  ],
})

import { createParser, validators } from '@walmart/sophia-tagging'
import searchBody from '../parsers/common/search'

export default createParser({
  fields: [
    ...searchBody,
    { from: 'result.query', to: 'search_term', validate: [] },
    {
      from: 'url',
      to: 'search_path',
      validate: [validators.isRequired],
    },
  ],
})

import { getTenant, isObject } from '../../Helpers'
import constants from './constants.json'
import {
  fetchLocalStorageFlowTypes,
  fetchLocalStorageSubstitutionArray,
  updateLocalStorageSubstitutionArray,
  updateLocalStorageFlowTypes,
} from './localStorage'

const { COMMON, TENANT, SALE_CHANNEL, EVENTS } = constants
const getPrice = (prices = {}) => {
  const DEFAULT_PRICE = 0
  if (!prices) return DEFAULT_PRICE
  const normalPrice = prices.BasePriceReference || 0
  const promoPrice = prices.BasePriceSales || 0
  return normalPrice > promoPrice && promoPrice > 0 ? promoPrice : normalPrice
}

const getCategory = (categories) => {
  if (!Array.isArray(categories) || !categories.length) return ''
  const firstCategoryLevel = categories[0].split('/')
  return firstCategoryLevel.shift()
}

const getIndex = (productSelectedSKU, infoListPage) => {
  const actualPage = infoListPage.page
  const totalPages = infoListPage.nbPages
  const totalProducts = infoListPage.nbHits
  const currentProducts = infoListPage.products

  if (!actualPage || !totalPages || !currentProducts || !productSelectedSKU || !totalProducts) return 0

  const indexFound = currentProducts.findIndex(({ sku }) => sku === productSelectedSKU) + 1

  const indexNotFound = indexFound === 0
  const isFirstPage = actualPage === 1
  const isIntermediumPage = actualPage > 1 && actualPage < totalPages
  const isLastPage = actualPage === totalPages && totalPages > 1

  if (indexNotFound) return 0

  if (isFirstPage) return indexFound

  if (isIntermediumPage) {
    const previousPage = actualPage - 1
    const totalPreviousProducts = currentProducts.length * previousPage
    return totalPreviousProducts + indexFound
  }

  if (isLastPage) {
    const totalPreviousProducts = totalProducts - currentProducts.length
    return totalPreviousProducts + indexFound
  }

  return 0
}

const itemsParser = (data, infoListPage) => {
  if (!Array.isArray(data) || data.length < 1) return []

  return data.map((item) => {
    const finalPrice = getPrice(item.price)
    const category = getCategory(item.categorias)
    const index = item.listIndex || getIndex(item.sku, infoListPage)

    return {
      currency: COMMON.CURRENCY,
      index,
      item_description: item.description,
      item_department: item.attributes?.department,
      item_brand: item.brand,
      item_category: category,
      item_id: item.sku,
      item_name: item.displayName,
      price: finalPrice,
      product_number: item.itemNumber,
      isMKP: item.isMKP,
      ...(item.quantity && { quantity: item.quantity }),
    }
  })
}

const getSaleChannel = () => {
  switch (getTenant()) {
    case TENANT.CATEX_ESP:
      return `${SALE_CHANNEL}-${TENANT.CATEX}`
    case TENANT.SOD_ESP:
      return `${SALE_CHANNEL}-${TENANT.SOD}`
    default:
      return SALE_CHANNEL
  }
}

const getDataCollection = (data) => {
  return {
    origin: data.origin,
    sale_channel: getSaleChannel(),
    store_number: data.selectedWalstoreStore?.id || data.selectedSODStoreId,
  }
}

const getItemFlowTypeArray = (itemSku, itemsFlowTypesObject) => {
  return Object.prototype.hasOwnProperty.call(itemsFlowTypesObject, itemSku)
    ? itemsFlowTypesObject[itemSku].flowType?.split(',')
    : []
}

const updateItemFlowTypeArray = (itemFlowType, flowTypeArray) => {
  const cleanFlowTypeArray = flowTypeArray.filter((flowType) => flowType !== itemFlowType)

  return [...cleanFlowTypeArray, itemFlowType]
}

const buildFlowType = (itemSku, itemFlowType, itemsFlowTypesObject) => {
  const flowTypeArray = getItemFlowTypeArray(itemSku, itemsFlowTypesObject)
  const updatedFlowTypeArray = updateItemFlowTypeArray(itemFlowType, flowTypeArray)

  return updatedFlowTypeArray.toString()
}

const handleAddToCartFlowType = (itemSku, itemsFlowTypesObject, flowType, itemQuantity) => {
  let updatedItemQuantity = 0

  if (!Object.prototype.hasOwnProperty.call(itemsFlowTypesObject, itemSku)) {
    updatedItemQuantity = itemQuantity
  } else {
    updatedItemQuantity = itemsFlowTypesObject[itemSku].quantity + itemQuantity
  }

  return { ...itemsFlowTypesObject, [itemSku]: { flowType: flowType, quantity: updatedItemQuantity } }
}

const handleRemoveFromCartFlowType = (itemSku, itemsFlowTypesObject, flowType, itemQuantity) => {
  if (!Object.prototype.hasOwnProperty.call(itemsFlowTypesObject, itemSku)) {
    return itemsFlowTypesObject
  }

  const updatedItemQuantity = itemsFlowTypesObject[itemSku].quantity - itemQuantity

  if (updatedItemQuantity < 1) {
    // eslint-disable-next-line no-unused-vars
    const { [itemSku]: _, ...restItemsFlowTypesObject } = itemsFlowTypesObject
    return restItemsFlowTypesObject
  }

  return { ...itemsFlowTypesObject, [itemSku]: { flowType: flowType, quantity: updatedItemQuantity } }
}

const handleSubstitutionItems = (items) => {
  const substitutionItems = []

  items.map((item) => {
    substitutionItems.push(item.sku)
  })

  updateLocalStorageSubstitutionArray(substitutionItems)
}

const handleFlowType = (eventName, computedFlowType, data) => {
  const updatedFlowTypes = fetchLocalStorageFlowTypes()
  const updatedSubstitutionArray = fetchLocalStorageSubstitutionArray()
  let updatedComputedFlowType = computedFlowType

  if (eventName === EVENTS.ADD_TO_CART || eventName === EVENTS.REMOVE_FROM_CART) {
    const builtFlowType = buildFlowType(data.sku, computedFlowType, updatedFlowTypes)
    updateLocalStorageFlowTypes(data.sku, builtFlowType, data.quantity, eventName, updatedFlowTypes)
    updatedComputedFlowType = builtFlowType

    updateLocalStorageSubstitutionArray([])
  }

  if (eventName === EVENTS.VIEW_ITEM && computedFlowType === 'none') {
    const flowTypeArray = getItemFlowTypeArray(data.sku, updatedFlowTypes)
    updatedComputedFlowType = updatedSubstitutionArray.includes(data.sku) ? 'Substitution' : flowTypeArray.toString()

    updateLocalStorageSubstitutionArray([])
  }

  if (eventName === EVENTS.ITEM_LIST_VIEW && computedFlowType === 'Substitution') {
    handleSubstitutionItems(data.products)
  }

  if (eventName === EVENTS.VIEW_CART) {
    updateLocalStorageSubstitutionArray([])
  }

  return updatedComputedFlowType
}

export {
  isObject,
  getPrice,
  getIndex,
  itemsParser,
  getSaleChannel,
  getDataCollection,
  getItemFlowTypeArray,
  buildFlowType,
  updateItemFlowTypeArray,
  handleAddToCartFlowType,
  handleRemoveFromCartFlowType,
  handleSubstitutionItems,
  handleFlowType,
}

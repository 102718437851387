import { ADD_ONE_TO_CART } from '../types'
import { getProductLimit } from '../Helpers/index'

export default (item) => (dispatch, getState) => {
  const {
    campaignConfigReducer: {
      goToPayMessageConfig: { digitLimitToCarryConfig = {} },
    },
  } = getState()
  const { active, limit: carryLimit } = digitLimitToCarryConfig
  const limit = active && carryLimit ? carryLimit : item.max

  const productLimit = getProductLimit(item.max, limit)
  item.quantity = item.quantity >= productLimit ? productLimit : item.quantity
  if (productLimit !== item.max) {
    item.max = productLimit
  }

  dispatch({
    type: ADD_ONE_TO_CART,
    payload: {
      item,
    },
  })
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getImageUrl, hasHost, setURLParams } from '../../Helpers/imageHelper'
import constants from '../../common/constants'
import { sendGenericEvent } from '../../common/GoogleTagManager'

import ImageWrapper from '../ProductDetailPage/ImagePreview/ImageWrapper'

class LazyLoadImageContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      backgroundImage: '',
    }
  }

  confirmImageExist = async () => {
    const { sku } = this.props
    let { hitImage } = this.props

    if (hasHost(hitImage, constants.LIQUID_PIXEL_REDIRECT_HOST)) {
      hitImage = setURLParams(hitImage, { sink: 'format[webp]' })
    }
    const backgroundImage = await getImageUrl(hitImage)
    if (backgroundImage === constants.PRODUCT_WITHOUT_IMAGE) {
      sendGenericEvent('product-with-no-photo', { sku })
    }

    this.setState({
      backgroundImage,
    })
  }

  componentDidMount = async () => {
    await this.confirmImageExist()
  }

  render() {
    const { backgroundImage } = this.state
    const { className, style, scrollPosition, alt } = this.props

    return (
      <div className={className}>
        <ImageWrapper url={backgroundImage}>
          <LazyLoadImage
            id="lazy-img"
            className={className}
            alt={alt}
            src={backgroundImage}
            style={style}
            scrollPosition={scrollPosition}
          />
        </ImageWrapper>
      </div>
    )
  }
}

LazyLoadImageContainer.defaultProps = {
  className: '',
  style: {},
  scrollPosition: null,
  hitImage: '',
  alt: '',
  sku: '',
}

LazyLoadImageContainer.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  scrollPosition: PropTypes.object,
  hitImage: PropTypes.string,
  alt: PropTypes.string,
  sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default LazyLoadImageContainer

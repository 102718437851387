import { createParser } from '@walmart/sophia-tagging'

export default createParser({
  fields: [
    { from: 'maxPrice', to: 'max_price', validate: [] },
    { from: 'minPrice', to: 'min_price', validate: [] },
    { from: 'page', to: 'page', validate: [] },
    { from: 'origin', to: 'origin', validate: [] },
    { from: 'saleChannel', to: 'sale_channel', validate: [], transform: (value) => value ?? 'LIDERCL' },
  ],
})

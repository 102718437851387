import React from 'react'
import PropTypes from 'prop-types'
import RemainingToComplete from './components/RemainingToComplete'
import PackCompleted from './components/PackCompleted'
import './index.css'

const CompleteProductPack = ({ quantity, packSize, packPrice, regularUnitPrice, parentComponent }) => {
  const residueInPackSize = () => quantity % packSize
  const remainingPackSizeQuantity = () => (residueInPackSize() === 0 ? 0 : packSize - residueInPackSize())
  const remainingQuantity = () => (quantity <= packSize ? packSize - quantity : remainingPackSizeQuantity())
  const isPackCompleted = () => remainingQuantity() === 0

  const contextClass = parentComponent ? `complete-product-pack__${parentComponent}` : ''
  const containerClasses = `complete-product-pack__container ${contextClass}`

  return (
    <div data-testid="complete-product-pack" className={containerClasses}>
      {isPackCompleted() ? (
        <PackCompleted
          packSize={packSize}
          packPrice={packPrice}
          regularUnitPrice={regularUnitPrice}
          quantity={quantity}
        />
      ) : (
        <RemainingToComplete
          remainingQuantity={remainingQuantity()}
          packSize={packSize}
          packPrice={packPrice}
          regularUnitPrice={regularUnitPrice}
        />
      )}
    </div>
  )
}

CompleteProductPack.propTypes = {
  quantity: PropTypes.number.isRequired,
  packSize: PropTypes.number.isRequired,
  packPrice: PropTypes.number.isRequired,
  regularUnitPrice: PropTypes.number.isRequired,
  parentComponent: PropTypes.string,
}

CompleteProductPack.defaultProps = {
  parentComponent: undefined,
}

export default CompleteProductPack

import { REMOVE_ONE_FROM_CART } from '../types'

export default (item) => (dispatch) => {
  dispatch({
    type: REMOVE_ONE_FROM_CART,
    payload: {
      item,
    },
  })
}

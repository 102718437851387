import generateViewPromotionPayloadForG4 from '../contexts/AnalyticsContext/parsers/viewPromotionsParser'

// !!! DONT ADD NEW CODE HERE, REFER TO THE ANALYTICS CONTEXT IMPLEMENTATION !!!

const currencyCode = 'CLP'

export const ORIGINS = {
  PDP: 'PDP',
  PLP: 'PLP',
  SEARCH: 'Search',
  HOME_PAGE: 'HomePage',
  SHOPPING_CART: 'shoppingCart',
  CONFIRM_ACCOUNT: 'confirmAccount',
  RESET_CREDENTIALS: 'ResetCredentials',
}

export const CART_EVENTS = {
  ADD_TO_CART: 'addToCart',
  REMOVE_FROM_CART: 'removeFromCart',
  ADD_TO_CART_GA4: 'add_to_cart',
  REMOVE_FROM_CART_GA4: 'remove_from_cart',
  VIEW_CART: 'viewCart',
}

export const cleanDataLayerObject = () => window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.

export const generateAnalyticsListNames = () => {
  let categoryType = ''
  let categoriesRefinement = ''
  if (window.location.href.includes('search?query')) {
    categoryType = 'search'
    categoriesRefinement = new URLSearchParams(window.location.search).get('query') || ''
  } else {
    categoryType = 'category'
    const categoriesWithNoFormat = window.location.pathname.split('category/')[1]
    if (categoriesWithNoFormat) categoriesRefinement = categoriesWithNoFormat.replace(/_/g, ' ') || ''
  }
  return { categoryType, categoriesRefinement }
}

export const sendSimpleEvent = (event) => window.dataLayer.push({ event })

export const sendPageTypeDataToAnalytics = (page) => window.dataLayer.push({ event: 'pageType', page })

const getProductPrices = (item) => {
  const basePriceTLMCAlt = Math.min(item.price.BasePriceSales, item.price.BasePriceReference)
  const price = item.price.BasePriceTLMC > 0 ? item.price.BasePriceTLMC : basePriceTLMCAlt
  const highestPrice =
    item.price.BasePriceSales > item.price.BasePriceReference
      ? item.price.BasePriceSales
      : item.price.BasePriceReference
  return { price, highestPrice }
}

const generateProduct = (item, index) => {
  const { price, highestPrice } = getProductPrices(item)
  const { winningOffer = {}, isMKP, gtin13, displayName, sku, brand, categorias, attributes, description } = item
  const {
    sellerId = '',
    sellerName = '',
    sellerType = '',
    internationalSeller = false,
    country = '',
  } = winningOffer || {}

  const gaUniversalProduct = {
    name: displayName,
    id: `PROD_${sku}`,
    ean: gtin13,
    price: Math.trunc(price),
    brand,
    category: categorias ? categorias.join('|') : '',
    highestPrice: Math.trunc(highestPrice),
    position: index || 1,
    list: '',
    isMKP,
    sellerId,
    sellerName,
    sellerType,
    internationalSeller,
    country,
  }

  const ga4Product = {
    item_id: `PROD_${item.sku}`,
    item_name: displayName,
    affiliation: 'lider.cl',
    currency: currencyCode,
    index: index || 1,
    item_brand: brand,
    item_category: categorias ? categorias.join('|') : '',
    item_category2: sellerId,
    item_category3: sellerName,
    item_list_id: '',
    item_list_name: '',
    item_description: description,
    item_department: attributes?.department,
    price: Math.trunc(price),
    highestPrice: Math.trunc(highestPrice),
    isMKP,
    sellerId,
    sellerName,
    sellerType,
    internationalSeller,
    country,
  }

  return { gaUniversalProduct, ga4Product }
}

export const sendSearchResultReport = (event, params) => {
  const { query = '', products = [], page = 1, nbPages = 1 } = params

  const productsAvailableList = products.map((product) => product.available)
  const productsAvailable = productsAvailableList.filter((ele) => ele === true).length
  const path = `${window.location.hostname}${window.location.pathname}`
  const queryString = `?query=${query}`

  window.dataLayer.push({
    event,
    result: {
      path: query.length > 0 ? path + queryString : path,
      key: query,
      pageNumber: page,
      pages: nbPages,
      productsFound: products.length,
      productsAvailable,
      productNotAvailable: products.length - productsAvailable,
    },
  })
}

export const sendGenericEvent = (event, params) => {
  const { sku } = params
  window.dataLayer.push({
    event,
    sku,
    pdp: `${window.location.protocol}//${window.location.hostname}/catalogo/product/sku/${sku}`,
  })
}

export const sendproductClickToAnalytics = (item, category, type) => {
  if (!item.sku) return false
  const { gaUniversalProduct, ga4Product } = generateProduct(item)

  window.dataLayer.push({
    event: 'productClick',
    ecommerce: {
      currencyCode,
      click: {
        actionField: { list: `${type} ${category}` },
        products: [gaUniversalProduct],
      },
    },
  })

  window.dataLayer.push({
    event: 'select_item',
    version: 'ga4',
    ecommerce: {
      item_list_name: `${type} ${category}`,
      items: [ga4Product],
    },
  })

  return true
}

export const sendImpressionsToAnalytics = (items, category, type, dataCollectionInfo = {}) => {
  const gauProducts = []
  const ga4Products = []
  const itemsToIterate = items?.products || items || []
  itemsToIterate.forEach((item, i) => {
    const { gaUniversalProduct, ga4Product } = generateProduct(item, i)
    gauProducts.push({ ...gaUniversalProduct, list: `${type} ${category}`, position: i + 1 })
    ga4Products.push({ ...ga4Product, item_list_name: `${type} ${category}`, index: i + 1 })
  })

  window.dataLayer.push({
    event: 'impressions',
    ecommerce: {
      currencyCode,
      impressions: gauProducts,
    },
  })

  window.dataLayer.push({
    event: 'view_item_list',
    version: 'ga4',
    ecommerce: { items: ga4Products },
    dataCollection: dataCollectionInfo,
  })
}

export const sendDetailProductDataToAnalytics = (item, origin, dataCollectionInfo = {}) => {
  if (item.error) return
  const { gaUniversalProduct, ga4Product } = generateProduct(item)
  window.dataLayer.push({
    event: 'detail',
    ecommerce: {
      currencyCode,
      detail: {
        actionField: { list: origin },
        products: [gaUniversalProduct],
      },
    },
  })

  window.dataLayer.push({
    event: 'view_item',
    version: 'ga4',
    ecommerce: { items: [{ ...ga4Product, item_list_name: origin }] },
    dataCollection: dataCollectionInfo,
  })
}

export const sendCartDataToAnalytics = (item, event, quantity, origin, dataCollectionInfo = {}) => {
  const { gaUniversalProduct, ga4Product } = generateProduct(item)

  const eventObject = {
    event,
    ecommerce: {
      currencyCode,
    },
  }

  const eventObjectGa4 = {
    event: '',
    version: 'ga4',
    ecommerce: { items: [] },
    dataCollection: dataCollectionInfo,
  }

  const productQuantity = quantity || item.quantity
  const productsArray = [{ ...gaUniversalProduct, quantity: productQuantity, origin }]
  const productsGa4Array = [{ ...ga4Product, quantity: productQuantity, origin }]

  if (event === CART_EVENTS.REMOVE_FROM_CART) {
    eventObject.ecommerce.remove = {
      products: productsArray,
    }
    eventObjectGa4.event = CART_EVENTS.REMOVE_FROM_CART_GA4
  } else if (event === CART_EVENTS.ADD_TO_CART) {
    eventObject.ecommerce.add = {
      products: productsArray,
    }
    eventObjectGa4.event = CART_EVENTS.ADD_TO_CART_GA4
  }

  eventObjectGa4.ecommerce.items = productsGa4Array
  window.dataLayer.push(eventObject)
  window.dataLayer.push(eventObjectGa4)
}
export const sendImageViewDataListToAnalytics = (promoViews, origin, name) => {
  try {
    let promotionsFromObject = []

    if (!Array.isArray(promoViews)) promotionsFromObject = Object.values(promoViews)
    const gauPromos = []
    const promotionsAssigned = promotionsFromObject.length > 0 ? promotionsFromObject : promoViews
    promotionsAssigned.forEach((promoView, i) => {
      const { tag = '', brand = '' } = promoView
      const { adsName, adsPosition, adsLocationID, adsID } = generateViewPromotionPayloadForG4(promoView)

      const gauPromo = {
        id: name,
        name: tag !== '' ? tag : brand,
        creative: origin,
        position: i + 1,
      }

      const ga4Promo = {
        creative_name: origin,
        creative_slot: adsPosition,
        location_id: adsLocationID,
        promotion_id: adsID,
        promotion_name: adsName,
        items: [],
      }

      // promotions in ga4 is not possible to send all as array
      window.dataLayer.push({
        event: 'view_promotion',
        version: 'ga4',
        ecommerce: { ...ga4Promo },
      })

      gauPromos.push(gauPromo)
    })

    window.dataLayer.push({
      event: 'promoView',
      ecommerce: { promoView: { promotions: gauPromos } },
    })
  } catch (e) {
    console.error(promoViews, e)
  }
}

export const sendClickPromoDataToAnalyticsV2 = (analyticData) => {
  if (analyticData) {
    const { adsName, position, adsId } = analyticData

    window.dataLayer.push({
      event: 'promoClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: adsId || adsName,
              name: adsName,
              creative: 'ConnectBanners',
              position,
            },
          ],
        },
      },
    })
  }
}

export const sendClickPromoDataToAnalytics = (imageName, origin, index, id = '') => {
  try {
    window.dataLayer.push({
      event: 'promoClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: id !== '' ? id : imageName,
              name: imageName,
              creative: origin,
              position: index,
            },
          ],
        },
      },
    })

    window.dataLayer.push({
      event: 'select_promotion',
      version: 'ga4',
      ecommerce: {
        creative_name: imageName,
        creative_slot: origin,
        location_id: `${origin}_${index}`,
        promotion_id: id !== '' ? id : imageName,
        promotion_name: imageName,
        items: [],
      },
    })
  } catch (e) {
    console.error('Error', e, imageName)
  }
}

// !!! DONT ADD NEW CODE HERE, REFER TO THE ANALYTICS CONTEXT IMPLEMENTATION !!!

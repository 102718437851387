import ClientAPI from '../ClientAPI'

const makeRequestBody = (shoppingCartItems, storeId) => ({
  items: shoppingCartItems.map((item) => ({ sku: item.sku.toString(), qty: item.quantity })),
  storeId,
})

const getPriceInfo = (item) =>
  item.price ? { defaultQuantity: item.defaultQuantity, discount: item.discount, price: item.price } : {}

const skusReducer = (result) =>
  result.reduce(
    (acc, item) => ({
      ...acc,
      [item.sku]: {
        available: item.available,
        maxAvailable: item.maxAvailable,
        ...getPriceInfo(item),
      },
    }),
    {}
  )

const getSkusAvailabilityAndPrice = async (shoppingCartItems, storeId) => {
  const clientAPI = new ClientAPI()

  const requestData = makeRequestBody(shoppingCartItems, storeId)
  const result = await clientAPI.checkSKUs(requestData)
  return result ? skusReducer(result) : undefined
}

export default getSkusAvailabilityAndPrice

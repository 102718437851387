import { isObject } from '../../../Helpers'
import constants from '../constants.json'
import { getSaleChannel } from '../utils'

const changePriceFilterDataParser = (payload) => {
  if (!isObject(payload)) return {}

  const saleChannel = getSaleChannel()

  const dataCollection = {
    maxPrice: payload.data.maxPrice,
    minPrice: payload.data.minPrice,
    page: payload.data.page,
    flowType: payload.flowType,
    origin: payload.data.origin,
    sale_channel: saleChannel,
  }
  const result = {
    event: constants.EVENTS.CHANGE_PRICE_FILTER,
    dataCollection,
  }

  window.dataLayer.push(result)
  return result
}

export { changePriceFilterDataParser }

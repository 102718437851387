import React from 'react'
import DOMpurify from 'dompurify'
import PropTypes from 'prop-types'

const RenderInnerHTML = ({ ...props }) => {
  const { html } = props
  const sanitizer = DOMpurify.sanitize
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: sanitizer(html, { ADD_TAGS: ['iframe'] }) }} />
}

export default RenderInnerHTML

RenderInnerHTML.propTypes = {
  html: PropTypes.string.isRequired,
}

import { redirectOpen, getTenant, isAbsolutePath } from '../Helpers'
import constants from '../common/constants'
import ClientAPI from '../common/ClientAPI'
const api = new ClientAPI()

const findEventUrl = (data, urlType) => {
  const { isMobile, advisor } = data.extraProps
  const device = isMobile ? advisor.mobile : advisor.desktop
  return device[urlType]
}

const sendEventOrRedirect = (data, link, target, navigatorPath) => {
  if (data.isAdvisor) {
    const advisorEventClickInfo = {
      type: data.type,
      eventUrl: findEventUrl(data, constants.ADVISOR_CLICK_URL_EVENT),
      adUrl: data.adUrl,
      slotId: data.slotId,
      endTime: data.endTime,
    }
    if (isAbsolutePath(link)) {
      api
        .sendAdvisorEvent(advisorEventClickInfo)
        .finally(() => addSlotParam(data, data.isBlank ? '_blank' : target, navigatorPath))
        .catch(() => {
          // do nothing
        })
    } else {
      api.sendAdvisorEvent(advisorEventClickInfo)
      addSlotParam(data, data.isBlank ? '_blank' : target, navigatorPath)
    }
  } else {
    redirectOpen(link, target, navigatorPath)
  }
}

const addSlotParam = (data, target, navigatorPath) => {
  const hasUrlQueryString = data.redirectUrl && data.redirectUrl.includes('?')
  const initialParamSeparator = hasUrlQueryString ? '&' : '?'
  const redirectUrl = `${data.redirectUrl}${initialParamSeparator}slot_id=${data.slotId}&slot_name=${data.id}`
  redirectOpen(redirectUrl, data.isBlank ? '_blank' : target, navigatorPath)
}

const onViewHandle = (data) =>
  api.sendAdvisorEvent({
    type: data.type,
    eventUrl: findEventUrl(data, constants.ADVISOR_VIEW_URL_EVENT),
    adUrl: data.adUrl,
    slotId: data.slotId,
    startTime: data.startTime,
    endTime: data.endTime,
  })

const onImpressionHandle = (data) =>
  api.sendAdvisorEvent({
    type: data.type,
    eventUrl: findEventUrl(data, constants.ADVISOR_IMPRESSION_URL_EVENT),
    adUrl: data.adUrl,
    slotId: data.slotId,
  })

const validateSlotBanner = (bannerSlotInfo) => {
  const tenant = getTenant()
  const pathname = window.location?.pathname.replace(`/${tenant}`, '')
  if (bannerSlotInfo?.pathname === pathname) {
    return bannerSlotInfo
  } else {
    return undefined
  }
}

export { findEventUrl, sendEventOrRedirect, onImpressionHandle, onViewHandle, validateSlotBanner }

import { createParser } from '@walmart/sophia-tagging'
import searchBody from '../parsers/common/search'

export default createParser({
  fields: [
    ...searchBody,
    { from: 'dtSearchCategories', to: 'search_categories', validate: [] },
    {
      from: 'dtSearchCategories',
      to: 'category_l1',
      validate: [],
      transform: (categories) => {
        return categories?.split('/')[0]
      },
    },
    {
      from: 'dtSearchCategories',
      to: 'category_l2',
      validate: [],
      transform: (categories) => {
        return categories?.split('/')[1]
      },
    },
    {
      from: 'dtSearchCategories',
      to: 'category_l3',
      validate: [],
      transform: (categories) => {
        return categories?.split('/')[2]
      },
    },
  ],
})

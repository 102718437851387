import { getJSONItem, saveItemAsJSON } from '../../Helpers/localStorageHelper'
import constants from './constants.json'
import { handleAddToCartFlowType, handleRemoveFromCartFlowType } from './utils'

const { LOCAL_STORAGE, EVENTS } = constants

const fetchLocalStorageFlowTypes = () => {
  return getJSONItem(LOCAL_STORAGE.ITEMS_FLOWTYPES, {})
}

const fetchLocalStorageSubstitutionArray = () => {
  return getJSONItem(LOCAL_STORAGE.SUBSTITUTION_ITEMS, [])
}

const updateLocalStorageSubstitutionArray = (items) => {
  saveItemAsJSON(LOCAL_STORAGE.SUBSTITUTION_ITEMS, items)
}

const updateLocalStorageFlowTypes = (itemSku, itemFlowType, itemQuantity, eventName, itemsFlowTypesObject) => {
  let updatedItemsFlowTypes = {}

  if (eventName === EVENTS.ADD_TO_CART) {
    updatedItemsFlowTypes = handleAddToCartFlowType(itemSku, itemsFlowTypesObject, itemFlowType, itemQuantity)
  } else {
    updatedItemsFlowTypes = handleRemoveFromCartFlowType(itemSku, itemsFlowTypesObject, itemFlowType, itemQuantity)
  }

  saveItemAsJSON(LOCAL_STORAGE.ITEMS_FLOWTYPES, updatedItemsFlowTypes)
}

export {
  fetchLocalStorageFlowTypes,
  fetchLocalStorageSubstitutionArray,
  updateLocalStorageSubstitutionArray,
  updateLocalStorageFlowTypes,
}

import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { ACTIONS, CONSTANTS, SUB_STEP } from '../constants'

export const LateralPanelContext = createContext({
  isOpen: false,
  cartModalIsOpen: false,
  cartModalErrorIsOpen: false,
  cartModalCoordinatesErrorIsOpen: false,
  storeSelectorTabShowChoosen: null,
  setStoreSelectorTabPickup: () => {},
  setStoreSelectorTabHomeDelivery: () => {},
  setStoreSelectorTabDefault: () => {},
  closeCartModal: () => {},
  openCartModal: () => {},
  closeCartModalError: () => {},
  openCartModalError: () => {},
  closeCartModalCoordinatesError: () => {},
  openCartModalCoordinatesError: () => {},
  closePanel: () => {},
  handleClosePanel: () => {},
  activeComponent: null,
  subStep: null,
  isErrorModalOpen: false,
  isLoadingOverlay: false,
  isSubstitutionViewOpen: false,
  isAlternativeProductsModalOpen: false,
  showCart: () => {},
  openShoppingCart: () => {},
  openStoreSelector: () => {},
  showStoreSelectorFromCart: () => {},
  handleStoreSelectorAction: () => {},
  showLogin: () => {},
  showSoldOutValidation: () => {},
  showSoldOutProducts: () => {},
  showLoadingOverlay: () => {},
  showErrorOverlay: () => {},
  showModalError: () => {},
  closeErrorModal: () => {},
  showDeliveryAddressForm: () => {},
  openSubstitutionView: () => {},
  closeSubstitutionView: () => {},
  openAlternativeProducts: () => {},
  closeAlternativeProducts: () => {},
  showPromotionsValidation: () => {},
})

const initialState = {
  activeComponent: CONSTANTS.none,
  subStep: null,
  isOpen: false,
  cartModalIsOpen: false,
  cartModalErrorIsOpen: false,
  cartModalCoordinatesErrorIsOpen: false,
  storeSelectorTabShowChoosen: null,
  isErrorModalOpen: false,
  isLoadingOverlay: false,
  isSubstitutionViewOpen: false,
  isAlternativeProductsModalOpen: false,
}

function lateralPanelReducer(state, action) {
  switch (action.type) {
    case ACTIONS.OPEN_STORE_SELECTOR:
      return { ...state, isOpen: true, activeComponent: CONSTANTS.storeSelector }
    case ACTIONS.SHOW_STORE_SELECTOR_FROM_CART:
      return { ...state, activeComponent: CONSTANTS.storeSelector, subStep: SUB_STEP.FROM_CART }
    case ACTIONS.SHOW_CART:
      return { ...state, activeComponent: CONSTANTS.shoppingCart }
    case ACTIONS.OPEN_CART:
      return { ...state, activeComponent: CONSTANTS.shoppingCart, isOpen: true }
    case ACTIONS.SHOW_LOGIN:
      return { ...state, activeComponent: CONSTANTS.login }
    case ACTIONS.SHOW_SOLD_OUT_VALIDATION:
      return { ...state, activeComponent: CONSTANTS.soldOutItemsValidation }
    case ACTIONS.SHOW_SOLD_OUT_PRODUCTS:
      return { ...state, activeComponent: CONSTANTS.soldOutProducts }
    case ACTIONS.SAVE_STORE:
      if (state.subStep === SUB_STEP.FROM_CART) {
        return { ...state, subStep: null, activeComponent: CONSTANTS.shoppingCart }
      }
      return { ...state, subStep: null, activeComponent: CONSTANTS.none, isOpen: false }
    case ACTIONS.OPEN_CART_MODAL:
      return { ...state, cartModalIsOpen: true }
    case ACTIONS.CLOSE_CART_MODAL:
      return { ...state, cartModalIsOpen: false }
    case ACTIONS.OPEN_CART_MODAL_ERROR:
      return { ...state, cartModalErrorIsOpen: true }
    case ACTIONS.CLOSE_CART_MODAL_ERROR:
      return { ...state, cartModalErrorIsOpen: false }
    case ACTIONS.OPEN_CART_MODAL_COORDINATES_ERROR:
      return { ...state, cartModalCoordinatesErrorIsOpen: true }
    case ACTIONS.CLOSE_CART_MODAL_COORDINATES_ERROR:
      return { ...state, cartModalCoordinatesErrorIsOpen: false }
    case ACTIONS.SHOW_LOADING_OVERLAY:
      return { ...state, isLoadingOverlay: true }
    case ACTIONS.OVERLAY_ERROR:
      return { ...state, isLoadingOverlay: false, isErrorModalOpen: true, activeComponent: CONSTANTS.shoppingCart }
    case ACTIONS.MODAL_ERROR:
      return { ...state, isErrorModalOpen: true, activeComponent: CONSTANTS.shoppingCart }
    case ACTIONS.CLOSE_ERROR_MODAL:
      return { ...state, isErrorModalOpen: false }
    case ACTIONS.SHOW_DELIVERY_ADDRESS_FORM:
      return { ...state, activeComponent: CONSTANTS.deliveryAddressForm }
    case ACTIONS.OPEN_SUBSTITUTION_VIEW:
      return { ...state, isSubstitutionViewOpen: true }
    case ACTIONS.CLOSE_SUBSTITUTION_VIEW:
      return { ...state, isSubstitutionViewOpen: false }
    case ACTIONS.OPEN_ALTERNATIVE_PRODUCTS_MODAL:
      return { ...state, isAlternativeProductsModalOpen: true }
    case ACTIONS.CLOSE_ALTERNATIVE_PRODUCTS_MODAL:
      return { ...state, isAlternativeProductsModalOpen: false }
    case ACTIONS.SHOW_PROMOTIONS_VALIDATION:
      return { ...state, isOpen: true, activeComponent: CONSTANTS.promotionsValidation }
    case ACTIONS.SET_STORE_SELECTOR_TAB_PICKUP:
      return { ...state, storeSelectorTabShowChoosen: CONSTANTS.pickupMethod }
    case ACTIONS.SET_STORE_SELECTOR_TAB_HOMEDELIVERY:
      return { ...state, storeSelectorTabShowChoosen: CONSTANTS.homeDeliveryMethod }
    case ACTIONS.SET_STORE_SELECTOR_TAB_DEFAULT:
      return { ...state, storeSelectorTabShowChoosen: null }
    default:
      return initialState
  }
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(lateralPanelReducer, initialState)

  const closeCartModal = () => dispatch({ type: ACTIONS.CLOSE_CART_MODAL })
  const openCartModal = () => dispatch({ type: ACTIONS.OPEN_CART_MODAL })

  const closeCartModalError = () => dispatch({ type: ACTIONS.CLOSE_CART_MODAL_ERROR })
  const openCartModalError = () => dispatch({ type: ACTIONS.OPEN_CART_MODAL_ERROR })

  const closeCartModalCoordinatesError = () => dispatch({ type: ACTIONS.CLOSE_CART_MODAL_COORDINATES_ERROR })
  const openCartModalCoordinatesError = () => dispatch({ type: ACTIONS.OPEN_CART_MODAL_COORDINATES_ERROR })

  const closePanel = () => dispatch({ type: ACTIONS.CLOSE_PANEL })
  const handleClosePanel = () => {
    if (!state.isSubstitutionViewOpen && !state.cartModalErrorIsOpen) {
      if (state.cartModalIsOpen) {
        closeCartModal()
      } else if (state.cartModalCoordinatesErrorIsOpen) {
        closeCartModalCoordinatesError()
        showStoreSelectorFromCart()
      } else {
        closePanel()
      }
    }
  }

  const showCart = () => dispatch({ type: ACTIONS.SHOW_CART })
  const openShoppingCart = () => dispatch({ type: ACTIONS.OPEN_CART })

  const openStoreSelector = () => dispatch({ type: ACTIONS.OPEN_STORE_SELECTOR })
  const showStoreSelectorFromCart = () => dispatch({ type: ACTIONS.SHOW_STORE_SELECTOR_FROM_CART })
  const handleStoreSelectorAction = () => dispatch({ type: ACTIONS.SAVE_STORE })

  const showLogin = () => dispatch({ type: ACTIONS.SHOW_LOGIN })

  const showSoldOutValidation = () => dispatch({ type: ACTIONS.SHOW_SOLD_OUT_VALIDATION })
  const showSoldOutProducts = () => dispatch({ type: ACTIONS.SHOW_SOLD_OUT_PRODUCTS })

  const showLoadingOverlay = () => dispatch({ type: ACTIONS.SHOW_LOADING_OVERLAY })
  const showErrorOverlay = () => dispatch({ type: ACTIONS.OVERLAY_ERROR })

  const showModalError = () => dispatch({ type: ACTIONS.MODAL_ERROR })
  const closeErrorModal = () => dispatch({ type: ACTIONS.CLOSE_ERROR_MODAL })

  const showDeliveryAddressForm = () => dispatch({ type: ACTIONS.SHOW_DELIVERY_ADDRESS_FORM })

  const openSubstitutionView = () => dispatch({ type: ACTIONS.OPEN_SUBSTITUTION_VIEW })
  const closeSubstitutionView = () => dispatch({ type: ACTIONS.CLOSE_SUBSTITUTION_VIEW })

  const openAlternativeProducts = () => dispatch({ type: ACTIONS.OPEN_ALTERNATIVE_PRODUCTS_MODAL })
  const closeAlternativeProducts = () => dispatch({ type: ACTIONS.CLOSE_ALTERNATIVE_PRODUCTS_MODAL })
  const showPromotionsValidation = () => dispatch({ type: ACTIONS.SHOW_PROMOTIONS_VALIDATION })

  const setStoreSelectorTabPickup = () => dispatch({ type: ACTIONS.SET_STORE_SELECTOR_TAB_PICKUP })
  const setStoreSelectorTabHomeDelivery = () => dispatch({ type: ACTIONS.SET_STORE_SELECTOR_TAB_HOMEDELIVERY })
  const setStoreSelectorTabDefault = () => dispatch({ type: ACTIONS.SET_STORE_SELECTOR_TAB_DEFAULT })

  return (
    <LateralPanelContext.Provider
      value={{
        isOpen: state.isOpen,
        activeComponent: state.activeComponent,
        cartModalIsOpen: state.cartModalIsOpen,
        cartModalErrorIsOpen: state.cartModalErrorIsOpen,
        cartModalCoordinatesErrorIsOpen: state.cartModalCoordinatesErrorIsOpen,
        storeSelectorTabShowChoosen: state.storeSelectorTabShowChoosen,
        subStep: state.subStep,
        isErrorModalOpen: state.isErrorModalOpen,
        isLoadingOverlay: state.isLoadingOverlay,
        isSubstitutionViewOpen: state.isSubstitutionViewOpen,
        isAlternativeProductsModalOpen: state.isAlternativeProductsModalOpen,
        closeCartModal,
        openCartModal,
        closeCartModalError,
        openCartModalError,
        closeCartModalCoordinatesError,
        openCartModalCoordinatesError,
        closePanel,
        handleClosePanel,
        showCart,
        openShoppingCart,
        openStoreSelector,
        showStoreSelectorFromCart,
        handleStoreSelectorAction,
        showLogin,
        showSoldOutValidation,
        showSoldOutProducts,
        showLoadingOverlay,
        showErrorOverlay,
        showModalError,
        closeErrorModal,
        showDeliveryAddressForm,
        openSubstitutionView,
        closeSubstitutionView,
        openAlternativeProducts,
        closeAlternativeProducts,
        showPromotionsValidation,
        setStoreSelectorTabPickup,
        setStoreSelectorTabHomeDelivery,
        setStoreSelectorTabDefault,
      }}
    >
      {children}
    </LateralPanelContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useLateralPanelContext = () => useContext(LateralPanelContext)

export default Provider

/**
 * @external https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 */
function slugify(string) {
  return string
    .toString()
    .normalize('NFD')
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .replace(/[\u0300-\u036f]/g, '')
}

export default slugify

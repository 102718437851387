import getValueFrom from '../../../Helpers/objHelpers'

const generateViewPromotionPayloadForG4 = (promoView) => {
  const redirectUrl = getValueFrom(promoView, 'advisor.desktop.redirectUrl', getValueFrom(promoView, 'redirectUrl', ''))
  const urlFields = decodeURI(redirectUrl).split('?').pop()
  const urlData = Object.fromEntries(urlFields.split('&').map((value) => value.split('=')))
  const adsIDMatch = getValueFrom(urlData, 'ads_name', '').match(/ID:(\d+)/)
  const adsLocationID = getValueFrom(urlData, 'ads_position', '').match(/\d+/)
  const adsIDIndex = '1'
  const locationIDIndex = '0'

  return {
    adsID: getValueFrom(adsIDMatch, adsIDIndex, ''),
    adsPosition: getValueFrom(urlData, 'ads_position', '').replace(/[^a-zA-Z]/g, ''),
    adsLocationID: getValueFrom(adsLocationID, locationIDIndex, ''),
    adsName: getValueFrom(urlData, 'ads_name', ''),
  }
}

export default generateViewPromotionPayloadForG4

import { DELETE_FROM_CART } from '../types'

export default (item) => (dispatch) => {
  dispatch({
    type: DELETE_FROM_CART,
    payload: {
      item,
    },
  })
}

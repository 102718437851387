import { getTenant } from '.'

const localEnv = {
  baseURLbff: 'https://corporate-staging.walmartdigital.cl/',
  liderGrocery: 'https://lider.cl/supermercado',
  storageBaseUrl: 'https://corporate-staging.walmartdigital.cl/landing',
  miclubLider: 'https://www.miclublider.cl/',
}

export function getCookie(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  return match ? match[2] : null
}

export function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

const getConfig = (key) => {
  const config = window.__ENV__ || {}
  const env = process.env.NODE_ENV

  if (!key) {
    return env === 'development' ? localEnv : config
  }

  const result = `${env === 'development' && localEnv[key] ? localEnv[key] : config[key]}`.trim()

  if (key === 'baseURLbff') {
    if (typeof window !== 'undefined' && getCookie('baseURLbffOverride') !== null) {
      return getCookie('baseURLbffOverride')
    }

    return process.env.BFF_ENV === 'stub' ? 'http://localhost:1500/' : `${result}${getTenant()}/bff/`
  }

  return result
}

export default getConfig

import field_params_mapper from '../../params/field_params_mapper'

const RESULT_PRODUCTS_FROM_KEY = 'result.products'

export default [
  field_params_mapper,
  { from: 'result.page', to: 'page_number', validate: [] },
  { from: 'result.nbPages', to: 'pages_total', validate: [] },
  { from: 'result.nbHits', to: 'search_result_hits', validate: [] },
  {
    from: RESULT_PRODUCTS_FROM_KEY,
    to: 'items_found_quantity',
    validate: [],
    transform: (products) => products.length,
  },
  {
    from: RESULT_PRODUCTS_FROM_KEY,
    to: 'items_found_available_quantity',
    validate: [],
    transform: (products) => {
      return products.map((product) => product.available).filter((ele) => ele === true).length
    },
  },
  {
    from: RESULT_PRODUCTS_FROM_KEY,
    to: 'items_found_unavailable_quantity',
    validate: [],
    transform: (products) =>
      products.length - products.map((product) => product.available).filter((ele) => ele === true).length,
  },
  { from: 'dtSearchEngine', to: 'search_engine', validate: [], transform: (value) => value ?? 'default' },
  { from: 'selectedSODStoreId', to: 'sod_store_id', validate: [] },
  { from: 'selectedWalstoreStore.id', to: 'walmart_store_id', validate: [] },
  { from: 'saleChannel', to: 'sale_channel', validate: [] },
]

import React from 'react'
import PropTypes from 'prop-types'
import {
  getRemainingToCompleteText,
  getRemainingToCompleteOneForFreeText,
  getRemainingOneForFreeText,
  getSaving,
} from '../../index.utils'
import constants from '../../../../common/constants'
import './index.css'

const RemainingToComplete = ({ remainingQuantity, packSize, packPrice, regularUnitPrice }) => {
  const isOneRemaining = () => remainingQuantity === constants.MIN_QUANTITY_REMAINING_TO_COMPLETE_PACK
  const isLastOneFree = () => getSaving(packPrice, packSize, regularUnitPrice) === regularUnitPrice

  let remainingQuantityText = getRemainingToCompleteText(remainingQuantity, packSize, packPrice)

  if (isLastOneFree()) {
    remainingQuantityText = isOneRemaining()
      ? getRemainingOneForFreeText()
      : getRemainingToCompleteOneForFreeText(remainingQuantity)
  }

  return <div className="complete-product-pack__remaining-to-complete">{remainingQuantityText}</div>
}

RemainingToComplete.propTypes = {
  remainingQuantity: PropTypes.number.isRequired,
  packSize: PropTypes.number.isRequired,
  packPrice: PropTypes.number.isRequired,
  regularUnitPrice: PropTypes.number.isRequired,
}

export default RemainingToComplete

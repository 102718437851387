import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@walmart-web/livingdesign-components'

const CustomButton = ({ title, onClick, className, disabled, onKeyPress }) => (
  <Button className={className} onClick={onClick} onKeyPress={onKeyPress} disabled={disabled}>
    {title}
  </Button>
)

CustomButton.defaultProps = {
  disabled: false,
  onKeyPress: () => {},
}

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
}

export default CustomButton

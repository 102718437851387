import { useDispatch } from 'react-redux'

import { useIsTenant } from '../../../contexts/TenantContext'
import { useAnalytics } from '../../../contexts/AnalyticsContext'

import addOneToCart from '../../../actions/addOneToCart'
import deleteFromCart from '../../../actions/deleteFromCart'
import removeOneFromCart from '../../../actions/removeOneFromCart'
import removeAllFromCart from '../../../actions/removeAllFromCart'
import addToCart from '../../../actions/addToCart'
import openCart from '../../../actions/openCart'
import { sendSPAClickEvent } from '../../../common/advisorSPAUtil'
import { getUserId } from '../../../contexts/UserDataContext'
import {
  getStoreFromLocalStorage,
  updateCartItems,
  getSoldOutProducts,
  addIndexPropertyFromOne,
} from '../../../../src/functional-components/ShoppingCart/utils'

import constants from '../../../common/constants'
import AnalyticsConstants from '../../../contexts/AnalyticsContext/constants.json'

import { useShoppingCartContext } from '../context/ShoppingCartContext'
import { useLateralPanelContext } from '../../LateralPanel/context/LateralPanelContext'
import { isProductQuantityLessThanLimit } from '../../../Helpers'
import getSubstitutes from '../../../common/clients/recommenderClient'
import { CART_EVENTS, generateAnalyticsListNames } from '../../../common/GoogleTagManager'
import { getUserWalmartIdAndEmail } from '../../../common/ProfileUtils'

function useShoppingCart() {
  const dispatch = useDispatch()
  const isTenantSod = useIsTenant(constants.tenant.SOD)
  const categoriesRefinement = generateAnalyticsListNames()?.categoriesRefinement
  const dtSearchCategories = decodeURIComponent(categoriesRefinement)
  const { getDataForGoogleAnalytics } = useAnalytics()
  const {
    shoppingCartItems,
    store,
    setSoldOutProducts,
    soldOutProducts,
    saveShoppingCart,
    setSubstituteProducts,
    recommendationTrackingId,
    setRecommendationTrackingId,
  } = useShoppingCartContext()
  const { openStoreSelector, openSubstitutionView, openAlternativeProducts } = useLateralPanelContext()
  const { EVENTS, FLOW_TYPES, ORIGINS_TAGGING, DEFAULT_QUANTITY } = AnalyticsConstants

  const handleIncrease = (product, origin = '') => {
    const item = { ...product, quantity: product.quantity + 1 }
    dispatch(addOneToCart(item))
    getDataForGoogleAnalytics({
      eventName: EVENTS.ADD_TO_CART,
      flowType: FLOW_TYPES.SHOPPING_CART,
      data: { ...item, origin, quantity: DEFAULT_QUANTITY, eventEcommerce: CART_EVENTS.ADD_TO_CART, shoppingCartItems },
    })
  }

  const handleRemove = (product, origin = '') => {
    dispatch(deleteFromCart(product))
    getDataForGoogleAnalytics({
      eventName: EVENTS.REMOVE_FROM_CART,
      flowType: FLOW_TYPES.SHOPPING_CART,
      data: { ...product, origin, eventEcommerce: CART_EVENTS.REMOVE_FROM_CART, shoppingCartItems },
    })
  }

  const handleDecrease = (product, origin = '', flowType, dtSearchEngine) => {
    const item = { ...product, quantity: product.quantity - 1 }
    if (item.quantity < 1) {
      dispatch(deleteFromCart(item))
    } else {
      dispatch(removeOneFromCart(item))
    }
    getDataForGoogleAnalytics({
      eventName: EVENTS.REMOVE_FROM_CART,
      flowType: flowType || FLOW_TYPES.SHOPPING_CART,
      data: {
        ...item,
        tracking_id: recommendationTrackingId,
        origin,
        quantity: DEFAULT_QUANTITY,
        eventEcommerce: CART_EVENTS.REMOVE_FROM_CART,
        shoppingCartItems,
        dtSearchEngine,
        ...(dtSearchCategories && { dtSearchCategories }),
      },
    })
  }

  const handleEmptyCart = () => dispatch(removeAllFromCart())

  const getCartItemBySku = (sku) => shoppingCartItems.find((cartItem) => cartItem.sku === sku)
  const getCartItemBySkuAndSellerId = (sku, sellerId) =>
    shoppingCartItems.find(
      (cartItem) => cartItem.sku === sku && (cartItem.winningOffer ? cartItem.winningOffer.sellerId === sellerId : true)
    )

  const dispatchAddToCart = (cartItem) => dispatch(addToCart(cartItem))

  const handleAddToCart = ({
    product,
    listProduct = [],
    origin = '',
    subOrigin = '',
    shouldOpenCart = true,
    flowType = '',
    dtSearchEngine = '',
    searchState = {},
    index = 0,
    bannerSlotInfo = {},
  }) => {
    if (isTenantSod && store === undefined) {
      openStoreSelector()
    } else {
      const cartItem = product.winningOffer
        ? getCartItemBySkuAndSellerId(product.sku, product.winningOffer.sellerId)
        : getCartItemBySku(product.sku)
      const newItem = cartItem ? { ...cartItem } : { ...product, quantity: 0 }
      if (isProductQuantityLessThanLimit(listProduct, newItem)) {
        dispatchAddToCart({ ...newItem, quantity: newItem.quantity + 1 })
        if (!isTenantSod && shouldOpenCart) dispatch(openCart())
        sendSPAEvent(product, cartItem)
        getDataForGoogleAnalytics({
          eventName: EVENTS.ADD_TO_CART,
          flowType,
          data: {
            ...product,
            tracking_id: recommendationTrackingId,
            listProduct,
            origin,
            subOrigin,
            store,
            quantity: DEFAULT_QUANTITY,
            eventEcommerce: CART_EVENTS.ADD_TO_CART,
            shoppingCartItems,
            dtSearchEngine,
            ...(dtSearchCategories && { dtSearchCategories }),
            sessionId: getUserId(),
            searchTerm: searchState.query,
            isSponsored: isSponsored(product.tracking),
            position: index,
            bannerSlotInfo,
          },
        })
      }
    }
  }

  const sendSPAEvent = (product, cartItem) => {
    if (product.tracking && cartItem === undefined) {
      sendSPAClickEvent(product)
    }
  }
  const isSponsored = (tracking) => {
    return tracking !== undefined ? true : false
  }

  const handleRemoveOneFromCart = (product, origin = '', flowType, dtSearchEngine) => {
    const cartItem = product.winningOffer
      ? getCartItemBySkuAndSellerId(product.sku, product.winningOffer.sellerId)
      : getCartItemBySku(product.sku)
    if (cartItem) {
      handleDecrease(cartItem, origin, flowType, dtSearchEngine)
    }
  }

  const getProductQuantityInCart = (product) => {
    const cartItem = product.winningOffer
      ? getCartItemBySkuAndSellerId(product.sku, product.winningOffer.sellerId)
      : getCartItemBySku(product.sku)
    return cartItem ? cartItem.quantity : 0
  }

  const handleSoldOutItems = async () => {
    const storeLocalStorage = getStoreFromLocalStorage()
    let soldOutItems = []
    if (isTenantSod && storeLocalStorage.id) {
      const skusInCart = await updateCartItems(shoppingCartItems, storeLocalStorage.id)
      if (skusInCart !== undefined) {
        soldOutItems = getSoldOutProducts(skusInCart)
        saveShoppingCart(skusInCart)
        setSoldOutProducts(soldOutItems)
      }
    }
    return soldOutItems.length > 0
  }

  const handleRemoveSoldOutUnits = () => {
    const filteredShoppingCart = shoppingCartItems
      .filter((item) => item.available && item.maxAvailable)
      .map((item) => ({
        ...item,
        quantity: Math.min(item.quantity, item.maxAvailable),
      }))

    saveShoppingCart(filteredShoppingCart)
    setSoldOutProducts([])
  }

  const excludeRecommendedProductsInCart = (recommendations) =>
    recommendations.filter((item) => !shoppingCartItems.some((cartItem) => item.sku === cartItem.sku))

  const handleReplaceSoldOutItem = async (soldOutItem, isOriginIntegrationEnable = false) => {
    const storeNumber = store.id
    const { result, trackingId } = await getSubstituteProducts(soldOutItem, isOriginIntegrationEnable)

    if (!result.error) {
      getDataForGoogleAnalytics({
        eventName: EVENTS.ITEM_LIST_VIEW,
        flowType: FLOW_TYPES.SUBSTITUTION,
        data: {
          products: addIndexPropertyFromOne(result.recommendations),
          tracking_id: trackingId,
          store_number: storeNumber,
          origin: ORIGINS_TAGGING.SHOPPING_CART,
        },
      })
    }
    setSubstituteProducts(result)
    setRecommendationTrackingId(trackingId)

    openSubstitutionView()
  }

  const handleAlternativeToSoldOutItem = async (soldOutItem, origin = '') => {
    const isOriginIntegrationEnable = false
    const storeNumber = store?.id || constants.stores.DEFAULT_STORE_ID
    const { result, trackingId } = await getSubstituteProducts(soldOutItem, isOriginIntegrationEnable)

    if (!result.error) {
      getDataForGoogleAnalytics({
        eventName: EVENTS.ITEM_LIST_VIEW,
        flowType: FLOW_TYPES.SUBSTITUTION_PLP,
        data: {
          products: addIndexPropertyFromOne(result.recommendations),
          tracking_id: trackingId,
          store_number: storeNumber,
          origin: origin,
        },
      })
    }

    result.origin = origin
    result.flowType = FLOW_TYPES.SUBSTITUTION_PLP

    setSubstituteProducts(result)
    setRecommendationTrackingId(trackingId)
    openAlternativeProducts()
  }

  const getSubstituteProducts = async (soldOutItem, isOriginIntegrationEnable = false) => {
    const storeNumber = store?.id || constants.stores.DEFAULT_STORE_ID
    const { sku } = soldOutItem
    const result = {
      error: false,
      soldOutItem,
      storeNumber,
      recommendations: [],
    }

    try {
      const walmartId = isOriginIntegrationEnable ? undefined : getUserWalmartIdAndEmail().walmartId
      const { data: recommendations, headers } = await getSubstitutes({ sku, storeId: storeNumber, walmartId })
      const trackingId = headers[constants.HEADERS.RECOMMENDER_ANALYTICS_ID]
      const excludedRecommendedProductsInCart = excludeRecommendedProductsInCart(recommendations)

      return { result: { ...result, recommendations: excludedRecommendedProductsInCart }, trackingId }
    } catch (error) {
      return { result: { ...result, error: true }, trackingId: null }
    }
  }

  const removeSoldOutItem = (soldOutItem, flowType = FLOW_TYPES.SHOPPING_CART) => {
    const filteredSoldOutItems = soldOutProducts.filter((item) => item.sku !== soldOutItem.sku)
    const filteredShoppingCart = shoppingCartItems.filter((item) => item.sku !== soldOutItem.sku)

    saveShoppingCart(filteredShoppingCart)
    setSoldOutProducts(filteredSoldOutItems)

    getDataForGoogleAnalytics({
      eventName: EVENTS.REMOVE_FROM_CART,
      flowType,
      data: {
        ...soldOutItem,
        tracking_id: recommendationTrackingId,
        origin: ORIGINS_TAGGING.SHOPPING_CART,
        quantity: soldOutItem.quantity,
        eventEcommerce: CART_EVENTS.REMOVE_FROM_CART,
      },
    })
  }

  const handleRemoveSoldOutItem = (soldOutItem) => {
    removeSoldOutItem(soldOutItem)
  }

  const handleReplaceSelectedItem = (soldOutItem, selectedItem, index) => {
    removeSoldOutItem(soldOutItem, FLOW_TYPES.SUBSTITUTION)
    dispatchAddToCart(selectedItem)

    getDataForGoogleAnalytics({
      eventName: EVENTS.ADD_TO_CART,
      flowType: FLOW_TYPES.SUBSTITUTION,
      data: {
        ...selectedItem,
        listIndex: index,
        tracking_id: recommendationTrackingId,
        origin: ORIGINS_TAGGING.SHOPPING_CART,
        store,
        quantity: selectedItem.quantity,
        eventEcommerce: CART_EVENTS.ADD_TO_CART,
      },
    })
  }

  return {
    handleIncrease,
    handleRemove,
    handleDecrease,
    handleEmptyCart,
    handleSoldOutItems,
    handleAddToCart,
    handleRemoveOneFromCart,
    getProductQuantityInCart,
    getCartItemBySku,
    getCartItemBySkuAndSellerId,
    handleRemoveSoldOutItem,
    handleRemoveSoldOutUnits,
    handleReplaceSoldOutItem,
    handleReplaceSelectedItem,
    handleAlternativeToSoldOutItem,
  }
}

export default useShoppingCart

import React from 'react'
import PropTypes from 'prop-types'
import Price from './Price'
import texts from '../../../common/texts.json'
import './RegularUnitPrice.css'

const RegularUnitPrice = ({ price, location }) => {
    const regularUnitPriceClass = `regular-unit-price__price-${location}`
    return (
        <div className={regularUnitPriceClass}>
            {texts.PRODUCT_PRICES.REGULAR_UNIT_PRICE_PREFIX_TEXT} <Price price={price}/> {texts.PRODUCT_PRICES.REGULAR_UNIT_PRICE_SUFFIX_TEXT}
        </div>
    )
}

RegularUnitPrice.propTypes = {
    price: PropTypes.number.isRequired,
    location: PropTypes.string,
}

RegularUnitPrice.defaultProps = {
    location: 'default',
}

export default RegularUnitPrice

import { priceFormatter } from '../../Helpers'
import texts from '../../common/texts.json'
import textTemplate from '../../common/textTemplate'

const {
  REMAINING_TO_COMPLETE_LABEL,
  REMAINING_TO_COMPLETE_ONE_FOR_FREE_LABEL,
  REMAINING_ONE_FOR_FREE_LABEL,
  SAVING_LABEL,
} = texts.PACK_PROMOTION

export const getRemainingToCompleteText = (remainingQuantity, packSize, packPrice) => {
  const packPriceFormatted = priceFormatter(packPrice)
  return textTemplate(REMAINING_TO_COMPLETE_LABEL, { remainingQuantity, packSize, packPriceFormatted })
}

export const getRemainingToCompleteOneForFreeText = (remainingQuantity) =>
  textTemplate(REMAINING_TO_COMPLETE_ONE_FOR_FREE_LABEL, { remainingQuantity })

export const getRemainingOneForFreeText = () => REMAINING_ONE_FOR_FREE_LABEL

export const getSavingText = (saving) => {
  const savingFormatted = priceFormatter(saving)
  return textTemplate(SAVING_LABEL, { savingFormatted })
}

export const getSaving = (packPrice, packSize, regularUnitPrice) => Math.abs(packPrice - packSize * regularUnitPrice)

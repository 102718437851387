import React from 'react'
import PropTypes from 'prop-types'
import { Button as LDButton } from '@walmart-web/livingdesign-components'
import { Minus, Plus } from '@walmart-web/livingdesign-icons'
import './index.css'

const StepperButton = ({ name, product, variant, onClick, ...rest }) => {
  const handleClick = () => {
    onClick(product)
  }

  return (
    <LDButton
      UNSAFE_className="stepper-button"
      variant={variant}
      leading={name === 'Minus' ? <Minus size="medium" /> : <Plus size="medium" />}
      onClick={handleClick}
      {...rest}
    />
  )
}

StepperButton.defaultProps = {
  variant: 'default',
}

StepperButton.propTypes = {
  name: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default StepperButton

const getValueFrom = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter((s) => s.length > 0)
      .reduce((res, key) => (res ? res[key] : res), obj)
  const valueFromPath = travel(/[,[\].]+?/)
  return valueFromPath || defaultValue
}

export default getValueFrom

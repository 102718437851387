import React from 'react'
import PropTypes from 'prop-types'
import { priceFormatter } from '../../Helpers'
import texts from '../../common/texts.json'
import './index.css'

const SavingsLabel = ({ savings, classNames }) => {
  return (
    <span className={`savings-label ${classNames}`}>
      {!!savings && `${texts.PRODUCT_PRICES.SAVING_LABEL_TEXT} ${priceFormatter(savings)}`}
    </span>
  )
}

SavingsLabel.defaultProps = {
  classNames: '',
}
SavingsLabel.propTypes = {
  savings: PropTypes.number.isRequired,
  classNames: PropTypes.string,
}

export default SavingsLabel
